import React, {useCallback, useEffect, useState} from 'react';
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {ApiQueryParam, ApiService} from "../../../service/ApiService";
import {Loader} from "../../../components/admin/Loader";
import {officeMemberResultCollectionType, officeMemberResultType} from "../../../types/Api/OfficeMemberResultType";
import {office} from "../../../models/office";
import {officeMember} from "../../../models/officeMember";
import {officeMemberHydrator} from "../../../models/hydrator/officeMemberHydrator";
import {BoxTitle} from "../../../components/admin/BoxTitle";
import {OfficeEdit} from "../../../components/admin/office/OfficeEdit";
import {DeleteConfirme} from "../../../components/admin/DeleteConfirme";
import {OfficeEditMember} from "../../../components/admin/office/OfficeEditMember";
import {scrollTo} from "../../../service/ToolsService";

export const Edit = () => {
  const navidate = useNavigate()
  const uuid: undefined | string = useParams().uuid;

  const [office, setOffice] = useState(undefined as undefined | office);
  const [officeMember, setOfficeMember] = useState([] as officeMember[]);
  const [loading, setLoading] = useState(false);
  const [officeToDelete, setOfficeToDelete] = useState(undefined as undefined | office);

  const findOffice = useCallback(() => {
    if (uuid) {
      const apiFilters: ApiQueryParam = new ApiQueryParam();

      apiFilters.addFilter({
        field: 'officeId',
        op: 'eq',
        value: uuid
      });

      apiFilters.addSort({
        by: 'order',
        direction: 'ASC'
      });

      setLoading(true);

      const result = ApiService.get(`/office-member`, apiFilters) as Promise<officeMemberResultCollectionType>;

      result
        .then((officeMemberResultCollection: officeMemberResultCollectionType) => {
          setOfficeMember(officeMemberResultCollection._embedded["office-members"].map((om: officeMemberResultType) => {
            return officeMemberHydrator.hydrate(om);
          }));
        })
        .catch((e: any) => {
          console.log(e);
          alert("Une erreur c'est produite.")
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uuid])

  const deleteOffice = useCallback(async (office: office): Promise<void> => {
    setLoading(true);
    await ApiService.delete(`/office/${office.officeId}`);
    navidate('/admin/office')
  }, [navidate])

  const officeUpdated = useCallback((office: office): void => {
    setOffice(office)
  }, [])

  useEffect(() => {
    setOffice(
      officeMember.length > 0 ? officeMember[0].office : undefined
    )
  }, [officeMember])

  useEffect(() => {
    findOffice()
  }, [findOffice, uuid]);

  return (
    <>
      <BoxTitle title="Modifier un bureau"/>

      {loading && (
        <Loader/>
      )}

      {!loading && office && (
        <OfficeEdit office={office} officeUpdated={officeUpdated} setOfficeToDelete={setOfficeToDelete}/>
      )}

      {!loading && office && (
        <>
          <OfficeEditMember />
        </>
      )}

      {officeToDelete && (
        <DeleteConfirme confirme={(c: boolean) => {
          if (c) {
            deleteOffice(officeToDelete);
          }

          setOfficeToDelete(undefined);
        }}/>
      )}
      {!office && (
        <NavLink to={`/admin/office`} onClick={scrollTo} className="btn btn-sm btn-info">Retour aux bureaux</NavLink>
      )}
    </>
  );
}
