import {modelHydrator} from "./_modelHydrator";
import {userResultType} from "../../types/Api/UserResultType";
import {user} from "../user";

export const userHydrator = {
  hydrate: (user: userResultType): user => {
    return Object.assign({
      userId: user.userId,
      username: user.username,
      email: user.email,
      roles: user.roles,
      enabled: user.enabled === 1,
      verified: user.verified === 1,
    }, modelHydrator.hydrate(user))
  }
}