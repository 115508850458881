import {modelHydrator} from "./_modelHydrator";
import {album} from "../album";
import {albumResultType} from "../../types/Api/AlbumResultType";

export const albumHydrator = {
  hydrate: (album: albumResultType): album => {
    return Object.assign({
      albumId: album.albumId,
      label: album.label,
      slug: album.slug,
      enabled: album.enabled === 1,
      photo: [],
    }, modelHydrator.hydrate(album))
  }
}