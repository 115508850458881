import React, {useCallback, useEffect, useState} from 'react';
import {BoxTitle} from "../BoxTitle";
import {CalendrierFiltre} from "./CalendrierFiltre";
import {season} from "../../../models/season";
import {CalendrierListe} from "./CalendrierListe";
import {CalendrierInscriptionModal} from "./CalendrierInscriptionModal";
import {race} from "../../../models/race";

export type calendrierFiltersType = {
  seasonId?: string;
  ranking?: boolean;
  ramble?: boolean;
  paid?: boolean;
  subsidized?: boolean;
  past?: boolean;
}

export type CalendrierBoxProps = {
  seasons: season[];
}

export const CalendrierBox = ({seasons}: CalendrierBoxProps) => {
  const [filters, setFilters] = useState({} as calendrierFiltersType);
  const [season, setSeason] = useState(undefined as undefined | season);
  const [race, setRace] = useState(undefined as undefined|race);

  const handleFilters = useCallback((f: calendrierFiltersType) => {
    setFilters(f)
  }, []);

  useEffect(() => {
    setSeason(seasons.find((s: season) => s.seasonId === filters.seasonId));
  }, [setSeason, seasons, filters]);

  return (
    <>
      <div className="__front_box__ mb-4">
        <BoxTitle title={`Calendrier Team Chartres - ${season?.label}`}/>
        <CalendrierFiltre seasons={seasons} setFilters={handleFilters}/>
        {season && (
          <CalendrierListe season={season} filters={filters} setRace={(r: race) => setRace(r)}/>
        )}
      </div>
      { race && (
        <CalendrierInscriptionModal race={race} close={() => setRace(undefined)} />
      )}
    </>
  );
}
