import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../redux/store";
import {Login} from "./auth/Login";
import {Outlet} from "react-router-dom";
import {Header} from "../components/admin/Header";
import {Sidebar} from "../components/admin/Sidebar";
import {me as findMe} from "../redux/auth_reducer";
import {user} from "../models/user";
import {Loader} from "../components/admin/Loader";

export const Admin = () => {
  const dispatch = useAppDispatch();

  const accessToken: string | undefined = useAppSelector((state) => state.auth.accessToken);
  const me: user | undefined = useAppSelector((state) => state.auth.me);
  const loadingMe: boolean = useAppSelector((state) => state.auth.loadingMe);
  const refresh: boolean = useAppSelector((state) => state.auth.refresh);

  useEffect(() => {
    if (me === undefined && accessToken !== undefined) {
      dispatch(findMe())
    }
  }, [me, accessToken, dispatch]);

  return (
    <div className="__admin__">
      {accessToken === undefined && (
        <Login/>
      )}

      {(loadingMe || refresh) && (
        <div className="__form-login__">
          <Loader/>
        </div>
      )}

      {accessToken !== undefined && me !== undefined && !refresh && (
        <>
          <Header/>
          <div className="d-flex">
            <Sidebar/>
            <div className="col p-3 __admin_content__">
              <Outlet/>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
