import React, {useEffect} from 'react';
import {ParcoursBoxLoader} from "./ParcoursBoxLoader";
import {ParcoursBox} from "./ParcoursBox";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {route} from "../../../models/route";
import {fetchData as fetchRoute} from "../../../redux/route_reducer";

export const ParcoursListe = () => {
  const dispatch = useAppDispatch();

  const loading: boolean = useAppSelector((state) => state.route.loading);
  const routes: route[] = useAppSelector((state) => state.route.data);

  useEffect(() => {
    dispatch(fetchRoute())
  }, [dispatch]);

  return (
    <div className="d-flex flex-wrap align-items-stretch">
      {loading && (
        <>
          {[...new Array(4)].map((i: number, index: number) => (
            <div className="col-sm-6 mb-3 p-2 text-center" key={index}>
              <ParcoursBoxLoader/>
            </div>
          ))}
        </>
      )}

      {!loading && routes.map((route: route, index: number) => (
        <div className="col-sm-6 p-2" key={index}>
          <ParcoursBox route={route}/>
        </div>
      ))}
    </div>
  );
}
