import React from 'react';

export type BoxTitleProps = {
  title: string;
}

export const BoxTitle = ({title}: BoxTitleProps) => {
  return (
    <>
      <div className="">
        <h5 className="h5 ps-3">{title}</h5>
        <hr/>
      </div>
    </>
  );
}
