import React, {useCallback, useState} from 'react';
import {useForm} from "react-hook-form";
import {Require} from "../Require";
import {NavLink, useNavigate} from "react-router-dom";
import {ApiError, ApiService} from "../../../service/ApiService";
import {officeResultType} from "../../../types/Api/OfficeResultType";
import {office} from "../../../models/office";
import {OfficeListBox} from "./OfficeListBox";
import {officeHydrator} from "../../../models/hydrator/officeHydrator";
import {scrollTo} from "../../../service/ToolsService";

type FormDataType = {
  label: string;
  enabled: boolean;
}

type OfficeEditMemberProps = {
  office?: office
  setOfficeToDelete?: (office: office) => void,
  officeUpdated?: (office: office) => void,
}

export const OfficeEditMember = ({office, setOfficeToDelete, officeUpdated}: OfficeEditMemberProps) => {
  const navigate = useNavigate();
  const [editOffice, setEditOffice] = useState(false);

  const setOfficeToUpdate = useCallback(() => {
    setEditOffice(true);
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: {errors}
  } = useForm<FormDataType>({
    defaultValues: {
      label: office?.label ?? '',
      enabled: office?.enabled ?? false,
    }
  });

  const onSubmit = async (data: FormDataType) => {
    const d = {
      label: data.label,
      enabled: data.enabled ? 1 : 0,
    };

    try {
      if (office) {
        const result = await ApiService.patch(`/office/${office.officeId}`, {param: () => d}) as officeResultType

        if (officeUpdated !== undefined) {
          officeUpdated(officeHydrator.hydrate(result))
        }

        setEditOffice(false);
      } else {
        const result = await ApiService.post('/office', {param: () => d}) as officeResultType
        navigate(`/admin/office/edit/${result.officeId}`);
      }


      scrollTo();
    } catch (e: any) {
      const error: ApiError = e as ApiError;

      error.errors?.forEach((e: {
        field: string,
        message: string
      }) => {
        setError(e.field as "label" | "enabled", {
          type: "manual",
          message: e.message
        })
      });
    }
  }

  return (
    <>
      {office && !editOffice && (
        <>
          <div className="w100 max-w450px p-2">
            <OfficeListBox office={office} setOfficeToDelete={setOfficeToDelete} setOfficeToUpdate={setOfficeToUpdate} />
          </div>
          <NavLink to={`/admin/office`} className="btn btn-sm btn-info">Retour aux bureaux</NavLink>
        </>
      )}

      {(!office || editOffice) && (
        <form className="p-3" method="post" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex">
            <div className="col max-w600px pe-4">
              <div className="form-check form-switch mb-3">
                <input className="form-check-input" type="checkbox" id="form-enabled" {...register('enabled')} />
                <label className="form-check-label" htmlFor="form-enabled">
                  {`${watch('enabled') ? 'Le bureau est actif' : 'Le bureau n\'est pas actif'}`}
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="form-label">Année <Require/></label>
                <input id="form-label" type="text" className="form-control" placeholder="~" {...register('label', {
                  required: 'L\'année est obligatoire',
                  validate: {
                    minLength: (value) =>
                      (value ?? '').length <= 255 || 'L\'année ne doit pas dépasser 255 caractères',
                  },
                })} />
                {errors.label && <p className="text-danger">{errors.label.message}</p>}
              </div>
            </div>
          </div>

          <div className="mt-3">
            <button className="btn btn-sm btn-success me-1" type="submit">Enregistrer</button>
            {office && (
              <button className="btn btn-sm btn-info me-1" type="button" onClick={() => {setEditOffice(false);}}>Annuler</button>
            )}
            {!office && (
              <NavLink to={`/admin/office`} onClick={scrollTo} className="btn btn-sm btn-info">Retour aux bureaux</NavLink>
            )}
          </div>
        </form>
      )}
    </>
  )
}
