import {modelHydrator} from "./_modelHydrator";
import {race} from "../race";
import {raceResultType} from "../../types/Api/RaceResultType";
import {seasonHydrator} from "./seasonHydrator";
import {participantHydrator} from "./participantHydrator";
import {participantResultType} from "../../types/Api/ParticipantResultType";

export const raceHydrator = {
  hydrate: (race: raceResultType): race => {
    return Object.assign({
      raceId: race.raceId,
      label: race.label,
      rideDate: race.rideDate,
      startRegistration: race.startRegistration ?? undefined,
      endRegistration: race.endRegistration ?? undefined,
      paid: race.paid === 1,
      subsidized: race.subsidized === 1,
      cancel: race.cancel === 1,
      participantLimit: race.participantLimit ?? undefined,
      link: race.link ?? undefined,
      ranking: race.ranking === 1,
      season: race.season ? seasonHydrator.hydrate(race.season) : undefined,
      participants: race.participants?.map((r: participantResultType) => participantHydrator.hydrate(r)),
    }, modelHydrator.hydrate(race))
  }
}