import {Admin} from "../pages/Admin";
import {Soon} from "../pages/Soon";
import {News} from "../pages/admin/News";
import {Dashboard} from "../pages/admin/Dashboard";
import {List as NewsList} from "../pages/admin/news/List";
import {Edit as NewsEdit} from "../pages/admin/news/Edit";
import {Order as NewsOrder} from "../pages/admin/news/Order";
import {User} from "../pages/admin/User";
import {List as UserList} from "../pages/admin/user/List";
import {Edit as UserEdit} from "../pages/admin/user/Edit";
import {Partner} from "../pages/admin/Partner";
import {List as PartnerList} from "../pages/admin/partner/List";
import {Edit as PartnerEdit} from "../pages/admin/partner/Edit";
import {Office} from "../pages/admin/Office";
import {List as OfficeList} from "../pages/admin/office/List";
import {Add as OfficeAdd} from "../pages/admin/office/Add";
import {Edit as OfficeEdit} from "../pages/admin/office/Edit";

const admin = {
  path: '/admin',
  element: <Admin />,
  errorElement : <Admin />,
  children: [
    {
      path: '',
      element: <Dashboard />,
    },
    {
      path: 'user',
      element: <User />,
      children: [
        {
          path: '',
          element: <UserList />,
        },
        {
          path: 'add',
          element: <UserEdit />,
        },
        {
          path: 'edit/:uuid',
          element: <UserEdit />,
        },
      ]
    },
    {
      path: 'news',
      element: <News />,
      children: [
        {
          path: '',
          element: <NewsList />,
        },
        {
          path: 'add',
          element: <NewsEdit />,
        },
        {
          path: 'edit/:uuid',
          element: <NewsEdit />,
        },
        {
          path: 'order',
          element: <NewsOrder />,
        },
      ]
    },
    {
      path: 'route',
      element: <Soon />,
      children: [
        {
          path: 'route',
          element: <Soon />,
        },
        {
          path: 'circuits',
          element: <Soon />,
        },
        {
          path: 'etapes',
          element: <Soon />,
        }
      ]
    },
    {
      path: 'season',
      element: <Soon />,
    },
    {
      path: 'equipment',
      element: <Soon />,
    },
    {
      path: 'office',
      element: <Office />,
      children: [
        {
          path: '',
          element: <OfficeList />,
        },
        {
          path: 'add',
          element: <OfficeAdd />,
        },
        {
          path: 'edit/:uuid',
          element: <OfficeEdit />,
        },
      ]
    },
    {
      path: 'partner',
      element: <Partner />,
      children: [
        {
          path: '',
          element: <PartnerList />,
        },
        {
          path: 'add',
          element: <PartnerEdit />,
        },
        {
          path: 'edit/:uuid',
          element: <PartnerEdit />,
        },
      ],
    },
    {
      path: 'contact',
      element: <Soon />,
    },
  ]
}

export default admin;