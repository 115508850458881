import {modelHydrator} from "./_modelHydrator";
import {officeResultType} from "../../types/Api/OfficeResultType";
import {office} from "../office";

export const officeHydrator = {
  hydrate: (office: officeResultType): office => {
    return Object.assign({
      officeId: office.officeId,
      label: office.label,
      slug: office.slug,
      enabled: office.enabled === 1
    }, modelHydrator.hydrate(office))
  }
}