import React, {useCallback, useEffect, useState} from 'react';
import {TableLineLoader} from "../../front/loader/TableLineLoader";
import DateService from "../../../service/DateService";
import {NavLink} from "react-router-dom";
import {PaginationType} from "../../../types/PaginationType";
import {ApiQueryParam, ApiService} from "../../../service/ApiService";
import {newsResultCollectionType, newsResultType} from "../../../types/Api/NewsResultType";
import {newsHydrator} from "../../../models/hydrator/newsHydrator";
import {DeleteConfirme} from "../DeleteConfirme";
import {Pagination} from "../Pagination";
import {news} from "../../../models/news";
import {scrollTo} from "../../../service/ToolsService";

export const NewsList = () => {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([] as news[]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [del, setDel] = useState(undefined as undefined | news);
  const [pagination, setPagination] = useState({
    page: 0,
    pageCount: 0,
    total: 0,
    count: count,
  } as PaginationType);

  const deleteNews = async (news: news) => {
    setLoading(true);
    await ApiService.delete(`/news/${news.newsId}`);

    findNews();
  };

  const findNews = useCallback((): void => {
    const apiFilters: ApiQueryParam = new ApiQueryParam();

    apiFilters.addSort({
      by: 'order',
      direction: 'ASC'
    });

    apiFilters.setPage(page);
    apiFilters.setCount(count);

    setLoading(true);
    const result: Promise<newsResultCollectionType> = ApiService.get('/news', apiFilters) as Promise<newsResultCollectionType>;

    result
      .then((r: newsResultCollectionType) => {
        setPagination({
          page: r._page,
          pageCount: r._page_count,
          total: r._total_items,
          count: count,
        });

        setNews(r._embedded.newss.map((news: newsResultType) => {
          return newsHydrator.hydrate(news);
        }));
      })
      .catch(() => {
        alert("Une erreur c'est produite.")

        setPagination({
          page: 0,
          pageCount: 0,
          total: 0,
          count: count,
        });
        setNews([]);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [page, count]);

  useEffect(() => {
    findNews();
  }, [findNews]);

  return (
    <>
      <table className="table table-responsive table-hover border">
        <thead>
        <tr className="table-primary">
          <th>Titre</th>
          <th className="text-center w80px">Texte</th>
          <th className="text-center w80px">Photo</th>
          <th className="text-center w80px">Visible</th>
          <th className="text-center w150px">Modification</th>
          <th className="w190px"></th>
        </tr>
        </thead>
        <tbody>
        {loading && (
          <tr>
            <td colSpan={7} className="pt-4 pb-4 ps-5 pe-5">
              <TableLineLoader/>
            </td>
          </tr>
        )}

        {!loading && news.map((n: news, i: number) => (
          <tr key={i} className="align-middle">
            <td>{n.title}</td>
            <td className="text-center">{n.text && (<i className="fa-regular fa-file-lines"/>)}</td>
            <td className="text-center">{n.photo && (<i className="fa-regular fa-image"/>)}</td>
            <td className="text-center">{n.enabled && (<i className="fa-solid fa-eye"/>)}</td>
            <td className="text-center">
              {DateService
                .toLocaleString(n.updatedAt ?? n.createdAt, {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false})
              }
            </td>
            <td className="text-end">
              <NavLink to={`/admin/news/edit/${n.newsId}`} onClick={scrollTo} className="btn btn-xs btn-primary me-1">
                <i className="fa-solid fa-pencil me-1"/> modifier
              </NavLink>
              <button className="btn btn-xs btn-danger" onClick={() => setDel(n)}><i className="fa-solid fa-xmark me-1"/>supprimer</button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      {del && (
        <DeleteConfirme confirme={(c: boolean) => {
          if (c) {
            deleteNews(del);
          }

          setDel(undefined);
        }}/>
      )}
      <Pagination pagination={pagination}
                  paginate={(p: number) => {
                    if (!loading) {
                      setPage(p);
                    }
                  }}
                  count={(c: number) => {
                    if (!loading) {
                      setPage(1);
                      setCount(c);
                    }
                  }}
      />
      <div className="mt-3 text-end">
        <NavLink to={`/admin/news/add`} onClick={scrollTo} className="btn btn-sm btn-primary me-1">
          <i className="fa-solid fa-plus me-1"/> Ajouter une actualité
        </NavLink>
        <NavLink to={`/admin/news/order`} onClick={scrollTo} className="btn btn-sm btn-info">
          <i className="fa-solid fa-shuffle me-1"/> Modifier l'ordre
        </NavLink>
      </div>
    </>
  );
}
