import React from 'react';
import {BoxTitle} from "../../../components/admin/BoxTitle";
import {OfficeEdit} from "../../../components/admin/office/OfficeEdit";

export const Add = () => {
  return (
    <>
      <BoxTitle title="Ajouter un bureau"/>
      <OfficeEdit />
    </>
  );
}
