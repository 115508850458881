import {conf} from '../env';

import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import {verbeHttpType} from '../types/VerbeHttpType';
import store from "../redux/store";
import {AuthService} from "./AuthService";
import {clearToken} from "../redux/auth_reducer";
import {StorageService} from "./StorageService";

export class ApiService {
  private static _refresh: boolean = false;

  public static async get(url: string, param?: ApiParamInterface) {
    return this._send({method: verbeHttpType.GET, url: url, param: param});
  }

  public static post(url: string, param?: ApiParamInterface) {
    return this._send({method: verbeHttpType.POST, url: url, param: param});
  }

  public static patch(url: string, param?: ApiParamInterface) {
    return this._send({method: verbeHttpType.PATCH, url: url, param: param});
  }

  public static delete(url: string) {
    return this._send({method: verbeHttpType.DELETE, url: url});
  }

  public static file(url: string, param?: ApiParamInterface) {
    return this._send({method: verbeHttpType.FILE, url: url, param: param});
  }

  private static _generateUrl(url: string, param?: ApiParamInterface) {
    let _param: string = conf.api.debug ? '?XDEBUG_SESSION_START=PHPSTORM' : '';

    if (param !== undefined) {
      for (const [key, value] of Object.entries(param.param())) {
        _param = this._generateGetParam(_param, key, value);
      }
    }

    return conf.api.uri + url + _param;
  }

  private static _generateGetParam(param: string, key: string, value: unknown): string {

    if (typeof value !== 'undefined' && value !== null) {
      if (typeof value === 'object') {
        for (const value2 of Object.values(value as object)) {
          param = this._generateGetParam(param, `${key}[]`, value2);
        }
      } else {
        param += (param === '' ? '?' : '&') + `${key}=${value}`;
      }
    }

    return param;
  }

  private static _send(options: optionSend): Promise<unknown> {
    let axiosHeaders = {
      accept: 'application/json',
      'content-type': options.method === verbeHttpType.FILE ? 'multipart/form-data' : 'application/json; charset=utf-8',
    };

    const accessToken: string | undefined = store.getState().auth.accessToken;

    if (accessToken !== undefined) {
      axiosHeaders = Object.assign(axiosHeaders, {
        authorization: 'Bearer ' + accessToken
      })
    }

    const myAxios: AxiosInstance = axios.create({headers: axiosHeaders, withCredentials: true});

    let response;

    switch (options.method) {
      case verbeHttpType.PATCH:
        response = myAxios.patch(
          this._generateUrl(options.url),
          options.param ? options.param.param() : null,
          {withCredentials: true}
        );
        break;

      case verbeHttpType.POST:
      case verbeHttpType.FILE:
        response = myAxios.post(
          this._generateUrl(options.url),
          options.param ? options.param.param() : null,
          {withCredentials: true}
        );
        break;

      case verbeHttpType.DELETE:
        response = myAxios.delete(
          this._generateUrl(options.url),
          {withCredentials: true}
        );
        break;

      case verbeHttpType.GET:
      default:
        response = myAxios.get(
          this._generateUrl(options.url, options.param),
          {withCredentials: true}
        );
        break;
    }

    return response
      .then((_response: AxiosResponse): Promise<unknown> => this._response(_response))
      .catch((_error: AxiosError): Promise<unknown> => this._error(_error, options));
  }

  private static _response(response: AxiosResponse): Promise<unknown> {

    return new Promise((resolve, reject) => {
      if (response.status === 204 || typeof response.data === 'object') {
        resolve(response.data);
      } else {
        reject(new Error('Bad response'));
      }
    });
  }

  private static async _error(error: AxiosError, options: optionSend): Promise<unknown> {
    if (!this._refresh && error.response?.status === 401) {
      this._refresh = true;

      if (StorageService.hasItem('refreshToken')) {
        try {
          await AuthService.loginByRefreshToken();

          const result = await this._send(options);

          this._refresh = false;

          return result;
        } catch (_) {
          store.dispatch(clearToken());
          StorageService.removeItem('refreshToken');
        }
      }
    }

    return new Promise((resolve, reject) => {
      const e: ApiError = {
        status: error.status ?? 0,
        code: error.code ?? '',
        errors: undefined,
        message: undefined,
      };

      if (error.response) {
        const data = error.response.data as ApiError;

        e.message = data.message;
        e.errors = data.errors;
      }

      reject(e);
    });
  }

}

export interface ApiParamInterface {
  param: () => {};
}

export class ApiQueryParam implements ApiParamInterface {
  private filters?: filter[];
  private sorts?: sort[];
  private page?: number;
  private count?: number;

  constructor(option?: {
    filters?: filter[];
    sorts?: sort[];
    page?: number;
    count?: number;
  }) {
    if (option) {
      this.filters = option.filters;
      this.sorts = option.sorts;
      this.page = option.page;
      this.count = option.count;
    }
  }

  setPage(page: number) {
    this.page = page;
  }

  setCount(count: number) {
    this.count = count;
  }

  addFilter(filter: filter) {
    if (this.filters === undefined) {
      this.filters = [];
    }
    this.filters.push(filter);

    return this;
  }

  addSort(sort: sort) {
    if (this.sorts === undefined) {
      this.sorts = [];
    }
    this.sorts.push(sort);

    return this;
  }

  param(): {} {
    let param: { [key: string]: string | number } = {};

    this.filters?.forEach((filter: filter, index: number) => {
      param[`filters[${index}][field]`] = filter.field;
      param[`filters[${index}][value]`] = filter.value;

      if (filter.op) {
        param[`filters[${index}][op]`] = filter.op;
      }

      if (filter.not) {
        param[`filters[${index}][op]`] = `${filter.not ? 'true' : 'false'}`;
      }
    });

    this.sorts?.forEach((sort: sort, index: number) => {
      param[`sorts[${index}][by]`] = sort.by;

      if (sort.direction) {
        param[`sorts[${index}][direction]`] = sort.direction;
      }
    });

    if (this.page) {
      param[`page`] = this.page;
    }

    if (this.count !== undefined) {
      param[`count`] = this.count;
    }

    return param;
  }

}

export type ApiError = {
  status: number,
  code: string,
  errors?: {field: string, message: string}[],
  message?: string
}

type filter = {
  op?: 'eq' | 'lt' | 'lte' | 'gt' | 'gte' | 'in' | 'like',
  field: string,
  value: string,
  not?: boolean,
}

type sort = {
  by: string,
  direction?: 'ASC' | 'DESC'
}

type optionSend = {
  method: string;
  url: string;
  param?: ApiParamInterface;
  refresh?: boolean;
};
