import {createBrowserRouter} from "react-router-dom";
import admin from "./admin";
import front from "./front";
import {Activate} from "../pages/Activate";

const router = createBrowserRouter([
  admin,
  front,
  {
    path: 'admin/activate-user/:userId/:token',
    element: <Activate />,
  },
]);

export default router;