import React, {useEffect, useState} from 'react';
import {news} from "../../../models/news";
import {NewsForm} from "../../../components/admin/news/NewsForm";
import {useNavigate, useParams} from "react-router-dom";
import {ApiService} from "../../../service/ApiService";
import {newsPostType, newsResultType} from "../../../types/Api/NewsResultType";
import {newsHydrator} from "../../../models/hydrator/newsHydrator";
import {Loader} from "../../../components/admin/Loader";
import {BoxTitle} from "../../../components/admin/BoxTitle";

export const Edit = () => {
  const uuid: undefined|string = useParams().uuid;
  const navigate = useNavigate();

  const [news, setNews] = useState(undefined as undefined | news);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (uuid) {
      const result = ApiService.get(`/news/${uuid}`) as Promise<newsResultType>;

      setLoading(true);

      result
        .then((r) => {
          setNews(newsHydrator.hydrate(r));
        })
        .catch(() => {
          alert("Une erreur c'est produite.")
        })
        .finally(() => {
          setLoading(false);
        });
    }

  }, [uuid]);


  const save = async (n: newsPostType) => {
    try {
    if (news) {
      await ApiService.patch(`/news/${news.newsId}`, { param: () => n })
    } else {
      await ApiService.post('/news', { param: () => n })
    }
    } catch (e) {
      alert("Une erreur c'est produite.")
    }

    navigate('/admin/news')
  }

  return (
    <>
      {loading && (
        <Loader />
      )}

      {!loading && (news || !uuid) && (
        <>
          <BoxTitle title={uuid ? 'Modifier une actualité' : 'Ajouter une actualité'}/>
          <NewsForm news={news} post={(n: newsPostType) => save(n)}/>
        </>
      )}
    </>
  );
}
