import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {ApiService} from "../../service/ApiService";
import {NavLink} from "react-router-dom";

type FormDataType = {
  password: string,
  repassword: string,
}

type PasswordFormProps = {
  userId: string;
  token: string;
}

export const PasswordForm = ({userId, token}: PasswordFormProps) => {
  const [loading, setLoading] = useState(false);
  const [activate, setActivate] = useState(false);

  const {register, handleSubmit, setError, formState: {errors}} = useForm<FormDataType>();

  const onSubmit = async (data: FormDataType) => {
    if (data.password !== data.repassword) {
      setError('repassword', {
        type: 'manual',
        message: 'Mot de passe et Confirmer doivent être identique.'
      })
    } else {
      const param = {
        password: data.password
      };

      try {
        setLoading(true);
        await ApiService.post(`/_/u/${userId}/t/${token}`, {param: () => param});
        setActivate(true);
      } catch (e: any) {
        setError('password', {type: 'manual', message: e.message})
      }

      setLoading(false);
    }
  }

  return (
    <>
      <form className="p-3" method="post" onSubmit={handleSubmit(onSubmit)}>
        {! activate && (
          <>
            <div className="mb-5 text-center bold">
              Pour activer votre compte, <br/>
              vous devez saisir votre mot de passe.
            </div>

            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-key"></i></span>
              <input type="password" autoComplete="true" className="form-control" placeholder="Mot de passe" {...register('password', {
                required: 'Mot de passe est requis'
              })} />
            </div>

            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-key"></i></span>
              <input type="password" autoComplete="true" className="form-control" placeholder="Confirmer" {...register('repassword', {
                required: 'Confirmer est requis',
              })} />
            </div>

            {errors.password && <div className="bold text-center text-danger">{errors.password.message}</div>}
            {errors.repassword && <div className="bold text-center text-danger">{errors.repassword.message}</div>}

            <div className="__form-login_button__">
              <button className="btn btn-success mt-3 bold" disabled={loading}>
                Enregistrer
                {loading && (
                  <span className="ms-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                )}
              </button>
              <a href="/admin" className="btn btn-warning btn-sm mt-2 text-light">Retour</a>
            </div>
          </>
        )}

        {activate && (
          <>
            <div className="mb-5 text-center bold">
              Votre compte est maintenant actif<br/>
              <NavLink to="/admin">me connecter</NavLink>
            </div>
          </>
        )}
      </form>
    </>
  );
}
