import React, {useEffect} from 'react';
import {PartenaireBox} from "../../components/front/PartenaireBox";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {fetchData as fetchPartner} from "../../redux/partner_reducer";
import {partner} from "../../models/partner";
import {BoxTitle} from "../../components/front/BoxTitle";

export const Partenaire = () => {
  const dispatch = useAppDispatch();

  // const loading: boolean = useAppSelector((state) => state.partner.loading);
  const partners: partner[] = useAppSelector((state) => state.partner.data);

  useEffect(() => {
    dispatch(fetchPartner())
  }, [dispatch]);

  return (
    <>
      <div className="__front_box__">
        <BoxTitle title="Nos partenaires"/>
        <div className="text-center">
          <div className="__front_partenaire_block__">
            <div className="d-flex flex-wrap justify-content-center">
              {partners.map((partner: partner, index: number) => (
                <PartenaireBox partner={partner} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
