import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {fetchData as fetchAlbum} from "../../redux/album_reducer";
import {BoxTitle} from "../../components/front/BoxTitle";
import {album} from "../../models/album";
import {AlbumList} from "../../components/front/album/AlbumListe";
import {AlbumBox} from "../../components/front/album/AlbumBox";
import {useParams} from "react-router-dom";

export const Album = () => {
  const slug = useParams().slug ?? '';

  const dispatch = useAppDispatch();

  const [albumSelected, setAlbumSelected] = useState(undefined as undefined | album);
  const loading: boolean = useAppSelector((state) => state.album.loading);
  const albums: album[] = useAppSelector((state) => state.album.data);

  const findAlbum = useCallback(() => {
    setAlbumSelected(albums.find((album: album) => album.slug === slug));
  }, [albums, slug]);


  useEffect(() => {

  }, [albums]);

  useEffect(() => {
    dispatch(fetchAlbum())
  }, [dispatch]);

  useEffect(() => {
    findAlbum()
  }, [findAlbum, slug]);

  return (
    <>
      {!albumSelected && (
        <div className="__front_box__ mb-3">
          <BoxTitle title="Photos"/>
          {!loading && (
            <AlbumList
              albums={albums}
              albumSelected={albumSelected}
            />
          )}
        </div>
      )}

      {albumSelected && (
        <AlbumBox album={albumSelected} back={() => setAlbumSelected(undefined)}/>
      )}
    </>
  );
}