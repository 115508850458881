import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import equipmentReducer from "./equipment_reducer";
import newsReducer from "./news_reducer";
import postOfficeReducer from './post_office_reducer';
import routeReducer from './route_reducer';
import seasonReducer from './season_reducer';
import weatherReducer from './weather_reducer';
import collectionReducer from "./collection_reducer";
import albumReducer from "./album_reducer";
import authReducer from "./auth_reducer";
import partnerReducer from "./partner_reducer";

const store =  configureStore({
    reducer: {
        auth: authReducer,
        album: albumReducer,
        collection: collectionReducer,
        equipment: equipmentReducer,
        news: newsReducer,
        partner: partnerReducer,
        postOffice: postOfficeReducer,
        route: routeReducer,
        season: seasonReducer,
        weather: weatherReducer,
    }
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector