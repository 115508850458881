import React from 'react';
import {office} from "../../../models/office";
import {useNavigate} from "react-router-dom";
import {scrollTo} from "../../../service/ToolsService";

type OfficeListBoxProps = {
  office: office,
  setOfficeToUpdate?: (office: office) => void,
  setOfficeToDelete?: (office: office) => void,
}

export const OfficeListBox = ({office, setOfficeToUpdate, setOfficeToDelete}: OfficeListBoxProps) => {
  const navigate = useNavigate();
  const editable = setOfficeToUpdate !== undefined;

  return (
    <div className="col">
      <div className={`__admin_office_card card shadow-sm ${office.enabled ? 'border-success' : ''} ${editable ? '' : 'cursor-pointer'}`}
           onClick={() => {
             if (!editable) {
               navigate(`/admin/office/edit/${office.officeId}`)
             }
           }}
      >
        <div className="card-body">
          <h5 className="card-title">{office.label}</h5>
          <p className="card-text">
            <strong>Actif :</strong> {office.enabled ? (<span className="badge bg-success">Oui</span>) : (<span className="badge bg-warning">Non</span>)}
          </p>
          <div className="d-flex justify-content-end">
            {setOfficeToUpdate && (
              <button className="btn btn-xs btn-primary me-1" onClick={() => {
                scrollTo();
                setOfficeToUpdate(office)
              }}><i className="fa-solid fa-pencil me-1"/> modifier
              </button>
            )}
            {setOfficeToDelete && (
              <button className="btn btn-xs btn-danger" onClick={() => {
                setOfficeToDelete(office)
              }}><i className="fa-solid fa-xmark me-1"/>supprimer
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
