import React, {useCallback, useEffect, useState} from 'react';
import {BoxTitle} from "../../components/front/BoxTitle";
import {EquipementBox} from "../../components/front/equipement/EquipementBox";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {fetchData as fetchEquipment} from "../../redux/equipment_reducer";
import {equipment} from "../../models/equipment";
import {EquipementFiltre} from "../../components/front/equipement/EquipementFiltre";
import {size} from "../../models/size";

export type equipementFiltersType = {
  type: string[],
  collection: string[],
  size: string[]
}

export const Equipement = () => {
  const dispatch = useAppDispatch();

  const [filters, setFilters] = useState({} as equipementFiltersType);
  const [equipmentsFiltered, setEquipmentsFiltered] = useState([] as equipment[]);
  const loading: boolean = useAppSelector((state) => state.route.loading);
  const equipments: equipment[] = useAppSelector((state) => state.equipment.data);

  const handleFilter = useCallback((f: equipementFiltersType) => {
    setFilters(f)
  }, []);

  const filterEquipment = useCallback(() => {
    setEquipmentsFiltered(
      equipments.filter((equipment: equipment): boolean => {

        if (filters.size && filters.size.length !== 0 && undefined === equipment.sizes?.find((size: size) => {
          return filters.size.includes(size.label)
        })
        ) {
          return false;
        }

        if (filters.collection && filters.collection.length !== 0 && !filters.collection.includes(equipment.collection.label)) {
          return false;
        }

        if (filters.type && filters.type.length !== 0 && !filters.type.includes(equipment.type ?? '')) {
          return false;
        }

        return true;
      })
    );
  }, [filters, equipments]);

  useEffect(() => {
    filterEquipment()
  }, [filters, equipments, filterEquipment]);

  useEffect(() => {
    dispatch(fetchEquipment())
  }, [dispatch]);

  return (
    <>
      <div className="__front_box__ mb-3">
        <BoxTitle title="Information"/>

        <div className="font-1_25 text-center border-">
          Pour les vêtements s'adresser à <br/>
          Francis Garnier 13 rue de Strasbourg 28300 Mainvilliers.<br/>
          <span className="bold">Tel :</span> 06-79-42-54-24<br/>
          <span className="bold">adresse mail :</span> garnier-roquin@orange.fr<br/>
          Dorénavant un acompte de 50% sera demandé à la commande.
        </div>
      </div>

      <div className="__front_box__">
        <BoxTitle title="Equipement"/>

        <EquipementFiltre equipments={equipments} setFilters={handleFilter}/>

        <div className="d-flex flex-wrap justify-content-center">
          {!loading && equipmentsFiltered.map((equipment: equipment, index: number) => (
            <EquipementBox equipment={equipment} key={index} className="m-3"/>
          ))}
        </div>
      </div>
    </>
  );
}
