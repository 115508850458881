import React from 'react';

export type DeleteConfirmeProps = {
  title?: string;
  message?: string;
  confirme: (c: boolean) => void;
}

export const DeleteConfirme = ({title, message, confirme}: DeleteConfirmeProps) => {
  return (
    <>
      <div className="__modal_container__ d-flex justify-content-center align-items-center">
        <div className="__modal_background__"/>
        <div className="__modal_box__ __front_box__ max-w70 max-h80">
          {title && (
            <div>
              <h2 className="__front_info_title__ m-0">{title}</h2>
              <hr className="w-50 ms-auto me-auto mt-3 mb-4"/>
            </div>
          )}

          <div>
            {message ?? 'Etes-vous sûr de vouloir supprimer définitivement ?'}
          </div>

          <p className="m-0 mt-5 text-end">
            <button className="btn btn-sm btn-danger me-1" onClick={() => confirme(true)}>Supprimer</button>
            <button className="btn btn-sm btn-info" onClick={() => confirme(false)}>Annuler</button>
          </p>
        </div>
      </div>
    </>
  );
}
