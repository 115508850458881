import React from 'react';
import {Background} from "../components/Background";
import {Header} from "../components/front/Header";
import {Footer} from "../components/front/Footer";
import {Outlet, useRouteError} from "react-router-dom";
import {Error} from "../components/Error";

export const Front = () => {
  const error = useRouteError();

  return (
    <div className="__front__">
      <Background/>
      <Header/>
      <div className="container">
        <>
          {error && (
            <Error error={error} />
          )}
          {!error && (
            <Outlet/>
          )}
        </>
      </div>
      <Footer/>
    </div>
  );
}
