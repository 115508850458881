import React from 'react';
import {PartnerList} from "../../../components/admin/partner/PartnerList";
import {BoxTitle} from "../../../components/admin/BoxTitle";

export const List = () => {
  return (
    <>
      <BoxTitle title="Partenaire"/>
      <PartnerList/>
    </>
  )
}