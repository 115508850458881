import React from 'react';
import {NewsList} from "../../../components/admin/news/NewsList";
import {BoxTitle} from "../../../components/admin/BoxTitle";

export const List = () => {
  return (
    <>
      <BoxTitle title="Actualité"/>
      <NewsList/>
    </>
  )
}