import {modelHydrator} from "./_modelHydrator";
import {season} from "../season";
import {seasonResultType} from "../../types/Api/SeasonResultType";
import {raceHydrator} from "./raceHydrator";
import {raceResultType} from "../../types/Api/RaceResultType";

export const seasonHydrator = {
  hydrate: (season: seasonResultType): season => {
    return Object.assign({
      seasonId: season.seasonId,
      label: season.label,
      enabled: season.enabled === 1,
      races: season.races?.map((r: raceResultType) => raceHydrator.hydrate(r))
    }, modelHydrator.hydrate(season))
  }
}