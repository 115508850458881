import {modelHydrator} from "./_modelHydrator";
import {news} from "../news";
import {newsResultType} from "../../types/Api/NewsResultType";

export const newsHydrator = {
  hydrate: (news: newsResultType): news => {
    return Object.assign({
      newsId: news.newsId,
      title: news.title ?? undefined,
      text: news.text ?? undefined,
      photo: news.photo ?? undefined,
      order: news.order ?? undefined,
      enabled: news.enabled === 1,
    }, modelHydrator.hydrate(news))
  }
}