import {modelHydrator} from "./_modelHydrator";
import {officeMemberResultType} from "../../types/Api/OfficeMemberResultType";
import {officeMember} from "../officeMember";
import {officeHydrator} from "./officeHydrator";
import {memberHydrator} from "./memberHydrator";

export const officeMemberHydrator = {
  hydrate: (officeMember: officeMemberResultType): officeMember => {
    return Object.assign({
      officeMemberId: officeMember.officeMemberId,
      label: officeMember.label,
      order: officeMember.order,
      deletable: officeMember.deletable === 1,
      office: officeHydrator.hydrate(officeMember.office),
      member: officeMember.member ? memberHydrator.hydrate(officeMember.member) : undefined,
    }, modelHydrator.hydrate(officeMember))
  }
}