import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiService} from "../service/ApiService";
import {postOfficeResultType} from "../types/Api/PostOfficeResultType";
import {postOfficeHydrator} from "../models/hydrator/postOfficeHydrator";
import {postOffice} from "../models/postOffice";
import {RootState} from "./store";

export const fetchData = createAsyncThunk('get-post-office', async (_, thunkAPI): Promise<any> => {
  const state: state = (thunkAPI.getState() as RootState).postOffice;

  if (state.init > 0) {
    return undefined;
  }

  const result: postOfficeResultType[] = await ApiService.get('/office/active') as postOfficeResultType[];

  return result.map((r: postOfficeResultType) => postOfficeHydrator.hydrate(r));
})

interface state {
  init: number;
  loading: boolean;
  data: postOffice[]
}

const initialState: state = {
  init: 0,
  loading: false,
  data: []
}

export const postOfficeSlice = createSlice({
  name: "post_office",
  initialState,
  reducers: {},
  extraReducers: (builder): void => {
    builder.addCase(fetchData.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(fetchData.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.init = Date.now();
        state.data = action.payload;
      }
      state.loading = false;
    })
    builder.addCase(fetchData.rejected, (state) => {
      state.loading = false;
    })
  }
});

export default postOfficeSlice.reducer;