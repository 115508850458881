import {modelHydrator} from "./_modelHydrator";
import {collection} from "../collection";
import {collectionResultType} from "../../types/Api/CollectionResultType";

export const collectionHydrator = {
  hydrate: (collection: collectionResultType): collection => {
    return Object.assign({
      collectionId: collection.collectionId,
      label: collection.label,
      enabled: collection.enabled === 1
    }, modelHydrator.hydrate(collection))
  }
}