import React from 'react';
import {equipment} from "../../../models/equipment";
import {size} from "../../../models/size";

type EquipementBoxProps = {
  className?: string
  equipment: equipment
}

export const EquipementBox = ({className, equipment}: EquipementBoxProps) => {
  return (
    <>
      <div className={`${className ?? ''} border border-radius-0_4 p-3 d-flex flex-column max-w280px w-100`}>
        <div className="text-center border-bottom pb-3 mb-3 h150px d-flex">
          <img className="img-fluid max-h100 rounded-3 m-auto" src={equipment.picture} alt={equipment.label}/>
        </div>
        <div className="text-center mb-2">
          <h6 className="mb-0">{equipment.label}</h6>
          <span className="font-0_80 italic">{equipment.collection.label}</span>
        </div>
        <div className="mt-auto mb-auto text-center">
          {equipment.sizes?.map((size: size, index: number) => (
            <span className={`badge ${(size.stock ?? 0) > 0 ? 'bg-success' : 'bg-danger text-decoration-line-through'} bg-opacity-75 ms-1 me-1`} key={index}>{size.label}</span>
          ))}
        </div>
        <div className="mt-3 text-center">
          <h6 className="text-center">Prix : {equipment.price}€</h6>
        </div>
      </div>
    </>
  );
}
