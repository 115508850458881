import React from 'react';
import {useForm} from "react-hook-form";
import {NavLink, useNavigate} from "react-router-dom";
import {Require} from "../Require";
import {user} from "../../../models/user";
import {ApiError, ApiService} from "../../../service/ApiService";
import {scrollTo} from "../../../service/ToolsService";

type FormDataType = {
  username: string;
  email: string;
  roles: string[];
  enabled: boolean;
}

type UserFormProps = {
  user?: user,
}

export const UserForm = ({user}: UserFormProps) => {
  const navigate = useNavigate();

  const allRole: string[] = [
    'ROLE_ADMIN',
    'ROLE_ACTUALITE',
    'ROLE_BUREAU',
    'ROLE_CALENDRIER',
    'ROLE_EQUIPEMENT',
    'ROLE_PARCOURS',
    'ROLE_PARTENAIRE',
  ];

  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: {errors}
  } = useForm<FormDataType>({
    defaultValues: {
      username: user?.username || 'jtest',
      email: user?.email || 'jeremy.jtoile@gmail.com',
      roles: user?.roles || ['ROLE_ACTUALITE'],
      enabled: user?.enabled ?? true,
    }
  });

  const onSubmit = async (data: FormDataType) => {
    if (data.roles.indexOf('ROLE_ADMIN') !== -1) {
      data.roles = ['ROLE_ADMIN'];
    }

    const u = {
      username: data.username,
      email: data.email,
      roles: JSON.stringify(data.roles),
      enabled: data.enabled ? 1 : 0,
    };

    try {
      if (user) {
        await ApiService.patch(`/user/${user.userId}`, {param: () => u})
      } else {
        await ApiService.post('/user', {param: () => u})
      }

      navigate('/admin/user');
      scrollTo();
    } catch (e: any) {
      const error: ApiError = e as ApiError;

      error.errors?.forEach((e: {
        field: string,
        message: string
      }) => {
        setError(e.field as "username" | "email" | "roles" | "enabled" | `root.${string}` | "root" | `roles.${number}`, {
          type: "manual",
          message: e.message
        })
      });

      //
    }
  }

  return (
    <>
      <form className="p-3" method="post" onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex">
          <div className="col max-w600px pe-4">
            <div className="mb-3 form-check form-switch">
              <input className="form-check-input" type="checkbox" id="form-enabled" {...register('enabled')} />
              <label className="form-check-label" htmlFor="form-enabled">
                {`${watch('enabled') ? 'L\'utilisateur est actif' : 'L\'utilisateur est désactivé'}`}
              </label>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="form-username">Login <Require/></label>
              <input id="form-username" type="text" className="form-control" placeholder="~" {...register('username', {
                required: 'Le login est requis',
                validate: {
                  minLength: (value) =>
                    (value ?? '').length <= 255 || 'Le login ne doit pas dépasser 255 caractères',
                },
              })} />
              {errors.username && <p className="text-danger">{errors.username.message}</p>}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="form-email">Email <Require/></label>
              <input id="form-email" type="text" className="form-control" placeholder="~" {...register('email', {
                required: 'L\'email est requis',
                validate: {
                  minLength: (value) =>
                    (value ?? '').length <= 255 || 'L\'email ne doit pas dépasser 255 caractères',
                },
              })} />
              {errors.email && <p className="text-danger">{errors.email.message}</p>}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="form-role">Roles <Require/></label>
              {allRole.map((r: string, index: number) => (
                <div className={"form-check" + (r === 'ROLE_ADMIN' ? " mb-3" : "")} key={index}>
                  <input id={`form-role-${index}`} type="checkbox" className="form-check-input" value={r}
                         disabled={r !== 'ROLE_ADMIN' && watch('roles').indexOf('ROLE_ADMIN') !== -1}
                         {...register('roles', {
                             required: 'Vous devez choisir au moins un role',
                           }
                         )} />
                  <label className="form-check-label cursor-pointer" htmlFor={`form-role-${index}`}>{r}</label>
                </div>
              ))}
              {errors.roles && <p className="text-danger">{errors.roles.message}</p>}
            </div>
          </div>
        </div>

        <div className="mt-3">
          <button className="btn btn-sm btn-success me-1" type="submit">Enregistrer</button>
          <NavLink to={`/admin/user`} onClick={scrollTo} className="btn btn-sm btn-info">Retour aux utilisateurs</NavLink>
        </div>
      </form>
    </>
  );
}
