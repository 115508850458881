import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {route} from "../models/route";
import {routeResultCollectionType, routeResultType} from "../types/Api/RouteResultType";
import {ApiQueryParam, ApiService} from "../service/ApiService";
import {routeHydrator} from "../models/hydrator/routeHydrator";
import DateService from "../service/DateService";
import {RootState} from "./store";

export const fetchData = createAsyncThunk('get-route', async (_, thunkAPI): Promise<any> => {
  const state: state = (thunkAPI.getState() as RootState).route;

  if (state.init > 0) {
    return undefined;
  }

  const apiFilters: ApiQueryParam = new ApiQueryParam();

  apiFilters.addFilter({
    field: 'rideDate',
    op: 'gte',
    value: DateService.getDate(new Date())
  });

  apiFilters.addSort({
    by: 'rideDate',
    direction: 'ASC'
  });

  const result: routeResultCollectionType = await ApiService.get('/route', apiFilters) as routeResultCollectionType;

  return {
    paginate: {
      page: result._page,
      pageCount: result._page_count,
      total: result._total_items
    },
    data: result._embedded.routes.map((route: routeResultType) => {
      return routeHydrator.hydrate(route);
    })
  };
})

interface state {
  init: number;
  loading: boolean;
  paginate?: {
    page: number;
    pageCount: number;
    total: number;
  }
  data: route[]
}

const initialState: state = {
  init: 0,
  loading: false,
  paginate: undefined,
  data: []
}

export const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {},
  extraReducers: (builder): void => {
    builder.addCase(fetchData.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(fetchData.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.paginate = action.payload.paginate;
        state.data = action.payload.data;
        state.init = Date.now();
      }
      state.loading = false;
    })
    builder.addCase(fetchData.rejected, (state) => {
      state.loading = false;
    })
  }
});

export default routeSlice.reducer;