import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {season} from "../models/season";
import {ApiQueryParam, ApiService} from "../service/ApiService";
import {seasonHydrator} from "../models/hydrator/seasonHydrator";
import {seasonResultCollectionType, seasonResultType} from "../types/Api/SeasonResultType";
import {RootState} from "./store";

export const fetchData = createAsyncThunk('get-season', async(_, thunkAPI): Promise<any> => {
  const state: state = (thunkAPI.getState() as RootState).season;

  if (state.init > 0) {
    return undefined;
  }

  const apiFilters: ApiQueryParam = new ApiQueryParam();

  apiFilters.addSort({
    by: 'label',
    direction: 'DESC'
  });

  const result: seasonResultCollectionType =  await ApiService.get('/season', apiFilters) as seasonResultCollectionType;

  return {
    paginate: {
      page: result._page,
      pageCount: result._page_count,
      total: result._total_items
    },
    data: result._embedded.seasons.map((season: seasonResultType) => {
      return seasonHydrator.hydrate(season);
    })
  };
})

interface state {
  init: number;
  loading: boolean;
  paginate?: {
    page: number;
    pageCount: number;
    total: number;
  }
  data: season[]
}

const initialState: state = {
  init: 0,
  loading: false,
  paginate: undefined,
  data: []
}

export const seasonSlice = createSlice({
  name: "season",
  initialState,
  reducers: {},
  extraReducers: (builder): void => {
    builder.addCase(fetchData.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(fetchData.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.paginate = action.payload.paginate;
        state.data = action.payload.data;
        state.init = Date.now();
      }

      state.loading = false;
    })
    builder.addCase(fetchData.rejected, (state) => {
      state.loading = false;
    })
  }
});

export default seasonSlice.reducer;