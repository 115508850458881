import {ApiService} from "./ApiService";
import {authResultType} from "../types/Api/AuthResultType";
import {StorageService} from "./StorageService";
import store from "../redux/store";
import {setToken} from "../redux/auth_reducer";
import {user} from "../models/user";

export class AuthService {
  public static async loginByUsernamePassword(username: string, password: string)
  {
    const data = {
      login: username,
      password: password
    };

    const {access_token, refresh_token}: authResultType = await ApiService.post('/auth/login', { param: () => data }) as authResultType;

    StorageService.setItem(refresh_token, 'refreshToken');

    return access_token;
  }

  public static async loginByRefreshToken()
  {
    const data = {
      refresh_token: StorageService.getItem('refreshToken')
    };

    const {access_token, refresh_token}: authResultType = await ApiService.post('/auth/refresh', { param: () => data }) as authResultType;

    store.dispatch(setToken(access_token));
    StorageService.setItem(refresh_token, 'refreshToken');

    return access_token;
  }

  public static hasRole(role: string): boolean
  {
    const me: user | undefined = store.getState().auth.me;

    return (me?.roles.includes(role) || me?.roles.includes('ROLE_ADMIN')) === true;
  }
}
