import React, {useCallback, useEffect, useState} from 'react';
import {TableLineLoader} from "../../front/loader/TableLineLoader";
import DateService from "../../../service/DateService";
import {NavLink} from "react-router-dom";
import {PaginationType} from "../../../types/PaginationType";
import {ApiQueryParam, ApiService} from "../../../service/ApiService";
import {partnerResultCollectionType, partnerResultType} from "../../../types/Api/PartnerResultType";
import {partnerHydrator} from "../../../models/hydrator/partnerHydrator";
import {DeleteConfirme} from "../DeleteConfirme";
import {Pagination} from "../Pagination";
import {partner} from "../../../models/partner";
import {scrollTo} from "../../../service/ToolsService";

export const PartnerList = () => {
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState([] as partner[]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [del, setDel] = useState(undefined as undefined | partner);
  const [pagination, setPagination] = useState({
    page: 0,
    pageCount: 0,
    total: 0,
    count: count,
  } as PaginationType);

  const deletePartner = async (partner: partner) => {
    setLoading(true);
    await ApiService.delete(`/partner/${partner.partnerId}`);

    findPartner();
  };

  const findPartner = useCallback((): void => {
    const apiFilters: ApiQueryParam = new ApiQueryParam();

    apiFilters.addSort({
      by: 'label',
      direction: 'ASC'
    });

    apiFilters.setPage(page);
    apiFilters.setCount(count);

    setLoading(true);
    const result: Promise<partnerResultCollectionType> = ApiService.get('/partner', apiFilters) as Promise<partnerResultCollectionType>;

    result
      .then((r: partnerResultCollectionType) => {
        setPagination({
          page: r._page,
          pageCount: r._page_count,
          total: r._total_items,
          count: count,
        });

        setPartner(r._embedded.partners.map((partner: partnerResultType) => {
          return partnerHydrator.hydrate(partner);
        }));
      })
      .catch(() => {
        alert("Une erreur c'est produite.")

        setPagination({
          page: 0,
          pageCount: 0,
          total: 0,
          count: count,
        });
        setPartner([]);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [page, count]);

  useEffect(() => {
    findPartner();
  }, [findPartner]);

  return (
    <>
      <table className="table table-responsive table-hover border">
        <thead>
        <tr className="table-primary">
          <th>Nom</th>
          <th className="text-center w80px">Description</th>
          <th className="text-center w80px">Photo</th>
          <th className="text-center w80px">Adresse</th>
          <th className="text-center w80px">Lien</th>
          <th className="text-center w80px">Visible</th>
          <th className="text-center w150px d-lg-table-cell d-none">Modification</th>
          <th className="w190px"></th>
        </tr>
        </thead>
        <tbody>
        {loading && (
          <tr>
            <td colSpan={8} className="pt-4 pb-4 ps-5 pe-5">
              <TableLineLoader/>
            </td>
          </tr>
        )}

        {!loading && partner.map((p: partner, i: number) => (
          <tr key={i} className="align-middle">
            <td>{p.label}</td>
            <td className="text-center">{p.description && (<i className="fa-regular fa-file-lines"/>)}</td>
            <td className="text-center">{p.photo && (<i className="fa-regular fa-image"/>)}</td>
            <td className="text-center">{p.adress && (<i className="fa-solid fa-location-dot"/>)}</td>
            <td className="text-center">{p.link && (<i className="fa-solid fa-at"/>)}</td>
            <td className="text-center">{p.enabled && (<i className="fa-solid fa-eye"/>)}</td>
            <td className="text-center d-lg-table-cell d-none">
              {DateService
                .toLocaleString(p.updatedAt ?? p.createdAt, {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false})
              }
            </td>
            <td className="text-end">
              <NavLink to={`/admin/partner/edit/${p.partnerId}`} onClick={scrollTo} className="btn btn-xs btn-primary me-1">
                <i className="fa-solid fa-pencil me-1"/> modifier
              </NavLink>
              <button className="btn btn-xs btn-danger" onClick={() => setDel(p)}><i className="fa-solid fa-xmark me-1"/>supprimer</button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      {del && (
        <DeleteConfirme confirme={(c: boolean) => {
          if (c) {
            deletePartner(del);
          }

          setDel(undefined);
        }}/>
      )}
      <Pagination pagination={pagination}
                  paginate={(p: number) => {
                    if (!loading) {
                      setPage(p);
                    }
                  }}
                  count={(c: number) => {
                    if (!loading) {
                      setPage(1);
                      setCount(c);
                    }
                  }}
      />
      <div className="mt-3 text-end">
        <NavLink to={`/admin/partner/add`} onClick={scrollTo} className="btn btn-sm btn-primary me-1">
          <i className="fa-solid fa-plus me-1"/> Ajouter un partenaire
        </NavLink>
      </div>
    </>
  );
}
