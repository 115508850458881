import React from 'react';
import {partner} from "../../models/partner";

type PartenaireBoxProps = {
  partner: partner,
}

export const PartenaireBox = ({partner}: PartenaireBoxProps) => {
  return (
    <>
      <div className={`border border-radius-0_4 p-3 d-flex flex-column max-w280px w-100 m-3`}>
        <div className="text-center border-bottom pb-3 mb-3 h150px d-flex">
          <img className="img-fluid max-h100 rounded-3 m-auto" src={partner.photo} alt={partner.label}/>
        </div>
        <div className="text-center">
          <h6 className="mb-2">{partner.label}</h6>
        </div>
        <div className="mt-auto mb-auto text-center">
          {partner.description && (
            <span className="d-block italic mb-2">{partner.description}</span>
          )}
          {partner.adress && (
            <span className="d-block font-0_80 mb-2">{partner.adress}</span>
          )}
          {partner.link && (
            <span className="d-block font-0_80"><a target="_blank" rel="noreferrer" href={partner.link}>{partner.link}</a></span>
          )}
        </div>
      </div>
    </>
  );
}
