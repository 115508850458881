import React from 'react';
import {useAppSelector} from "../../redux/store";
import {user} from "../../models/user";
import {BoxTitle} from "../../components/admin/BoxTitle";

export const Dashboard = () => {
  const me: user | undefined = useAppSelector((state) => state.auth.me);

  return (
    <>
      <BoxTitle title="Dashboard" />
      <table className="table table-responsive border max-w400px">
        <tbody>
        <tr>
          <td>userId</td>
          <td>{me?.userId}</td>
        </tr>
        <tr>
          <td>username</td>
          <td>{me?.username}</td>
        </tr>
        <tr>
          <td>email</td>
          <td>{me?.email}</td>
        </tr>
        <tr>
          <td>roles</td>
          <td>{me?.roles}</td>
        </tr>
        <tr>
          <td>enabled</td>
          <td>{me?.enabled ? 'oui' : 'non'}</td>
        </tr>
        <tr>
          <td>verified</td>
          <td>{me?.verified ? 'oui' : 'non'}</td>
        </tr>
        </tbody>
      </table>
    </>
  );
}
