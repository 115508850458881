import React, {useCallback, useEffect, useState} from 'react';
import {album} from "../../../models/album";
import {BoxTitle} from "../BoxTitle";
import {ApiQueryParam, ApiService} from "../../../service/ApiService";
import {photoResultCollectionType, photoResultType} from "../../../types/Api/PhotoResultType";
import {photoHydrator} from "../../../models/hydrator/photoHydrator";
import {photo} from "../../../models/photo";
import {AlbumBoxPhotosLoader} from "../loader/AlbumBoxPhotosLoader";
import {PhotoModal} from "./PhotoModal";
import {NavLink} from "react-router-dom";
import {scrollTo} from "../../../service/ToolsService";

export type AlbumBoxProps = {
  album: album,
  back: () => void,
}

export const AlbumBox = ({album, back}: AlbumBoxProps) => {
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState([] as photo[]);
  const [photoSelected, setPhotoSelected] = useState(undefined as undefined | photo);

  const findPhoto = useCallback(async () => {
    const apiFilters: ApiQueryParam = new ApiQueryParam();

    apiFilters.addFilter({
      field: 'albumId',
      value: album.albumId
    });
    apiFilters.setCount(0);

    const result: photoResultCollectionType = await ApiService.get('/photo', apiFilters) as photoResultCollectionType;
    const photos: photo[] = result._embedded.photos.map((photo: photoResultType) => photoHydrator.hydrate(photo));

    setPhotos(photos)
  }, [album.albumId])

  const handlePhoto = (i: number) => {
    if (photoSelected === undefined) {
      return ;
    }

    const index: number = photos.findIndex((item) => item.photoId === photoSelected.photoId)

    setPhotoSelected(photos[(index+i)%photos.length]);
  }

  useEffect(() => {
    setLoading(true);
    setPhotos([]);
    findPhoto().finally(() => setLoading(false));
  }, [album, findPhoto]);

  return (
    <>
      <div className="__front_box__ mb-3">
        <BoxTitle title={album.label}/>
        {loading && (
          <>
            <div className="d-flex flex-row flex-wrap">
              {[...new Array(6)].map((i: number, index: number) => (
                <div className="col-sm-4 col-md-3 col-lg-2 p-2" key={index}>
                  <AlbumBoxPhotosLoader/>
                </div>
              ))}
            </div>
          </>
        )}

        {!loading && (
          <>
            <div>
              {photoSelected?.photoId}
            </div>
            <div className="d-flex flex-row flex-wrap">
              {photos.map((photo: photo, index: number) => (
                <div className="col-sm-4 col-md-3 col-lg-2 p-2" key={index}>
                  <img className="img-fluid img-thumbnail cursor-pointer" src={photo.src} onClick={() => setPhotoSelected(photo)} alt={photo.photoId} />
                </div>
              ))}
            </div>

            {photoSelected && (
              <PhotoModal photo={photoSelected} close={() => setPhotoSelected(undefined)} handle={(i: number) => handlePhoto(i)} />
            )}

            <div className="text-end">
              <NavLink to="/photo" className="btn btn-primary" onClick={() => { scrollTo(); back(); }}>
                Retour
              </NavLink>
            </div>
          </>
        )}
      </div>
    </>
  );
}