import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {ApiService} from "../../../service/ApiService";
import {Loader} from "../../../components/admin/Loader";
import {PartnerForm} from "../../../components/admin/partner/PartnerForm";
import {partnerResultType} from "../../../types/Api/PartnerResultType";
import {partner} from "../../../models/partner";
import {partnerHydrator} from "../../../models/hydrator/partnerHydrator";
import {BoxTitle} from "../../../components/admin/BoxTitle";

export const Edit = () => {
  const uuid: undefined|string = useParams().uuid;

  const [partner, setPartner] = useState(undefined as undefined | partner);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (uuid) {
      const result = ApiService.get(`/partner/${uuid}`) as Promise<partnerResultType>;

      setLoading(true);

      result
        .then((r) => {
          setPartner(partnerHydrator.hydrate(r));
        })
        .catch(() => {
          alert("Une erreur c'est produite.")
        })
        .finally(() => {
          setLoading(false);
        });
    }

  }, [uuid]);

  return (
    <>
      {loading && (
        <Loader/>
      )}

      {!loading && (partner || !uuid) && (
        <>
          <BoxTitle title={uuid ? 'Modifier un partenaire' : 'Ajouter un partenaire'}/>
          <PartnerForm partner={partner} />
        </>
      )}
    </>
  );
}
