import {modelHydrator} from "./_modelHydrator";
import {circuit} from "../circuit";
import {stepHydrator} from "./stepHydrator";
import {circuitResultType} from "../../types/Api/CircuitResultType";

export const circuitHydrator = {
  hydrate: (circuit: circuitResultType): circuit => {
    return Object.assign({
      circuitId: circuit.circuitId,
      label: circuit.label,
      distance: circuit.distance ?? undefined,
      openRunner: circuit.openRunner ?? undefined,
      steps: circuit.steps.map((step: any) => {
        return stepHydrator.hydrate(step);
      })
    }, modelHydrator.hydrate(circuit))
  }
}