import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {ApiService} from "../../../service/ApiService";
import {Loader} from "../../../components/admin/Loader";
import {UserForm} from "../../../components/admin/user/UserForm";
import {userResultType} from "../../../types/Api/UserResultType";
import {user} from "../../../models/user";
import {userHydrator} from "../../../models/hydrator/userHydrator";
import {BoxTitle} from "../../../components/admin/BoxTitle";

export const Edit = () => {
  const uuid: undefined | string = useParams().uuid;

  const [user, setUser] = useState(undefined as undefined | user);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (uuid) {
      const result = ApiService.get(`/user/${uuid}`) as Promise<userResultType>;

      setLoading(true);

      result
        .then((r) => {
          setUser(userHydrator.hydrate(r));
        })
        .catch(() => {
          alert("Une erreur c'est produite.")
        })
        .finally(() => {
          setLoading(false);
        });
    }

  }, [uuid]);



  return (
    <>
      {loading && (
        <Loader/>
      )}

      {!loading && (user || !uuid) && (
        <>
          <BoxTitle title={uuid ? 'Modifier un utilisateur' : 'Ajouter un utilisateur'}/>
          <UserForm user={user}/>
        </>
      )}
    </>
  );
}
