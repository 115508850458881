import React from 'react';
import {PaginationType} from "../../types/PaginationType";

export type PaginationProps = {
  pagination: PaginationType;
  paginate: (p: number) => void;
  count: (c: number) => void;
}

export const Pagination = ({pagination, paginate, count}: PaginationProps) => {
  const resultPage = [10, 20, 30, 40, 50];

  return (
    <div className="d-flex justify-content-between">
      <div>
        <select className="form-select" value={pagination.count} onChange={(event) => {count(parseInt(event.target.value))}}>
          {resultPage.map((rp: number, i: number) => (
            <option value={rp} key={i}>{rp}</option>
          ))}
        </select>
      </div>
      <ul className="pagination pagination-sm">
        <li className={`page-item ${pagination.page === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => paginate(1)}><i className="fa-solid fa-angles-left"/></button>
        </li>

        {[...Array(pagination.pageCount)].map((_, i) => (
            <li key={i} className="page-item">
              <button className={`page-link ${pagination.page === (i + 1) ? 'active' : ''}`} onClick={() => paginate(i + 1)}>{i + 1}</button>
            </li>
          )
        )}

        <li className={`page-item ${pagination.page === pagination.pageCount ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => paginate(pagination.pageCount)}><i className="fa-solid fa-angles-right"/></button>
        </li>
      </ul>
      <div>
        {pagination.total} résultat{pagination.total > 1 ? 's' : ''}
      </div>
    </div>
  );
}
