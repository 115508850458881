import React from 'react';
import {UserList} from "../../../components/admin/user/UserList";
import {BoxTitle} from "../../../components/admin/BoxTitle";

export const List = () => {
  return (
    <>
      <BoxTitle title="Utilisateur" />
      <UserList/>
    </>
  )
}