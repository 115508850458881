import React, {useCallback, useEffect, useState} from 'react';
import {OfficeList} from "../../../components/admin/office/OfficeList";
import {ApiQueryParam, ApiService} from "../../../service/ApiService";
import {officeResultCollectionType, officeResultType} from "../../../types/Api/OfficeResultType";
import {officeHydrator} from "../../../models/hydrator/officeHydrator";
import {office} from "../../../models/office";
import {Loader} from "../../../components/admin/Loader";
import {NavLink} from "react-router-dom";
import {BoxTitle} from "../../../components/admin/BoxTitle";
import {scrollTo} from "../../../service/ToolsService";

export const List = () => {
  const [loading, setLoading] = useState(false);
  const [offices, setOffices] = useState([] as office[]);

  const findOffice = useCallback((): void => {
    const apiFilters: ApiQueryParam = new ApiQueryParam();

    apiFilters.addSort({
      by: 'enabled',
      direction: 'DESC'
    });

    apiFilters.addSort({
      by: 'label',
      direction: 'DESC'
    });

    setLoading(true);
    const result: Promise<officeResultCollectionType> = ApiService.get('/office', apiFilters) as Promise<officeResultCollectionType>;

    result
      .then((r: officeResultCollectionType) => {
        setOffices(r._embedded.offices.map((office: officeResultType) => {
          return officeHydrator.hydrate(office);
        }));
      })
      .catch(() => {
        alert("Une erreur c'est produite.")
        setOffices([]);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  useEffect(() => {
    findOffice();
  }, [findOffice]);

  return (
    <>
      <BoxTitle title="Bureau"/>
      {loading && (
        <Loader/>
      )}

      {!loading && (
        <>
          {offices && (
            <OfficeList offices={offices} />
          )}

          <div className="mt-3 text-start">
            <NavLink to={`/admin/office/add`} onClick={scrollTo} className="btn btn-sm btn-primary me-1">
              <i className="fa-solid fa-plus me-1"/> Ajouter un bureau
            </NavLink>
          </div>
        </>
      )}
    </>
  )
}
