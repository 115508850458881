import React, {useCallback, useEffect, useState} from 'react';
import {news} from "../../../models/news";
import {ApiQueryParam, ApiService} from "../../../service/ApiService";
import {newsResultCollectionType, newsResultType} from "../../../types/Api/NewsResultType";
import {newsHydrator} from "../../../models/hydrator/newsHydrator";
import {Loader} from "../../../components/admin/Loader";
import {NewsSortable} from "../../../components/admin/news/NewsSortable";
import {useNavigate} from "react-router-dom";

export const Order = () => {
  const navigate = useNavigate();

  const [news, setNews] = useState([] as news[]);
  const [loading, setLoading] = useState(false);

  const findNews = useCallback((): void => {
    const apiFilters: ApiQueryParam = new ApiQueryParam();

    apiFilters.addSort({
      by: 'order',
      direction: 'ASC'
    });
    apiFilters.setCount(-1);

    setLoading(true);
    const result: Promise<newsResultCollectionType> = ApiService.get('/news', apiFilters) as Promise<newsResultCollectionType>;

    result
      .then((r: newsResultCollectionType) => {
        setNews(r._embedded.newss.map((news: newsResultType) => {
          return newsHydrator.hydrate(news);
        }));
      })
      .catch(() => {
        alert("Une erreur c'est produite.")
        setNews([]);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  useEffect(() => {
    findNews();
  }, [findNews]);

  const onSave = async (news: news[]) => {
    console.log(news);

    const p = news.map((n: news, i: number) => {
      if (n.order !== i) {
        return ApiService.patch(`/news/${n.newsId}`, { param: () => {return {order: i}} })
      }

      return Promise<void>;
    });

    await Promise.all(p);
    navigate('/admin/news');
  }

  return (
    <>
      {loading && (
        <Loader />
      )}
      {!loading && (
        <NewsSortable news={news}  save={(n: news[]) => onSave(n)}/>
      )}
    </>
  );
}
