import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import {AuthService} from "../../service/AuthService";
import {scrollTo} from "../../service/ToolsService";

export const Sidebar = () => {
  const location = useLocation();
  const [submenu, setSubmenu] = useState('');
  const [submenuActive, setSubmenuActive] = useState('');

  const navigate = () => {
    setSubmenu('')
    scrollTo()
  }

  const open = (e: React.MouseEvent, s: string) => {
    e.preventDefault();
    setSubmenu(s === submenu ? '' : s)
  }

  const showSubmenu = (s: string) => {
    return submenu === s || submenuActive.includes(`/admin/${s}`);
  }

  useEffect(() => {
    setSubmenuActive(location.pathname);
  }, [location]);

  return (
    <>
      <div className="__admin_sidebar__ w180px">
        <div className="d-flex flex-column">
          { AuthService.hasRole('ROLE_USER') && (
            <div className="__admin_sidebar_menu__ __admin_sidebar_menu_hover__">
              <NavLink to="/admin/user" onClick={() => navigate()}>
                <i className="fa-solid fa-user-secret me-1"/>
                Utilisateur
              </NavLink>
            </div>
          )}

          { AuthService.hasRole('ROLE_ACTUALITE') && (
            <div className="__admin_sidebar_menu__ __admin_sidebar_menu_hover__">
              <NavLink to="/admin/news" onClick={() => navigate()}>
                <i className="fa-solid fa-circle-info me-1"/>
                Actualité
              </NavLink>
            </div>
          )}

          { AuthService.hasRole('ROLE_PARCOURS') && (
            <div className="__admin_sidebar_menu__">
              <NavLink to="/admin/route" onClick={(e) => open(e, 'route')}>
                <i className="fa-solid fa-bicycle me-1"/>
                Parcours
                <i className={`float-end fa-solid ${showSubmenu('route') ? 'fa-chevron-down' : 'fa-chevron-right'}`}/>
              </NavLink>
              {showSubmenu('route') && (
                <div className="__admin_sidebar_menu_submenu__ d-flex flex-column">
                  <NavLink to="/admin/route/route" onClick={(e) => navigate()}>
                    <i className="fa-solid fa-minus me-1"/>
                    les parcours
                  </NavLink>
                </div>
              )}
            </div>
          )}

          { AuthService.hasRole('ROLE_CALENDRIER') && (
            <div className="__admin_sidebar_menu__ __admin_sidebar_menu_hover__">
              <NavLink to="/admin/season" onClick={() => navigate()}>
                <i className="fa-solid fa-calendar-day me-1"/>
                Calendrier
              </NavLink>
            </div>
          )}

          { AuthService.hasRole('ROLE_EQUIPEMENT') && (
            <div className="__admin_sidebar_menu__ __admin_sidebar_menu_hover__">
              <NavLink to="/admin/equipment" onClick={() => navigate()}>
                <i className="fa-solid fa-shirt me-1"/>
                Equipement
              </NavLink>
            </div>
          )}

          { AuthService.hasRole('ROLE_BUREAU') && (
            <div className="__admin_sidebar_menu__ __admin_sidebar_menu_hover__">
              <NavLink to="/admin/office" onClick={() => navigate()}>
                <i className="fa-solid fa-users-line me-1"/>
                Bureau
              </NavLink>
            </div>
          )}

          { AuthService.hasRole('ROLE_PARTENAIRE') && (
            <div className="__admin_sidebar_menu__ __admin_sidebar_menu_hover__">
              <NavLink to="/admin/partner" onClick={() => navigate()}>
                <i className="fa-solid fa-handshake me-1"/>
                Partenaire
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
