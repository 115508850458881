import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {NavLink, useNavigate} from "react-router-dom";
import {partner} from "../../../models/partner";
import {UploadService} from "../../../service/UploadService";
import {Require} from "../Require";
import {ApiError, ApiService} from "../../../service/ApiService";
import {scrollTo} from "../../../service/ToolsService";

type FormDataType = {
  label: string;
  description?: string;
  adress?: string;
  link?: string;
  photo?: FileList;
  enabled: boolean;
}

type PartnerFormProps = {
  partner?: partner,
}

export const PartnerForm = ({partner}: PartnerFormProps) => {
  const pattern_link = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(:[0-9]{1,5})?(\/[^\s]*)?$/;
  const navigate = useNavigate();

  const [updateImage, setUpdateImage] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: {errors}
  } = useForm<FormDataType>({
    defaultValues: {
      label: partner?.label,
      description: partner?.description,
      adress: partner?.adress,
      link: partner?.link,
      enabled: partner?.enabled ?? true,
    }
  });

  const onSubmit = async (data: FormDataType) => {
    let photo: undefined | string = undefined;

    if (deleteImage) {
      photo = '';
    } else if (data.photo && data.photo.length > 0) {
      photo = await UploadService.handleFileToBase64(data.photo[0]);
    }

    const d = {
      label: data.label,
      description: data.description || undefined,
      adress: data.adress || undefined,
      link: data.link || undefined,
      photo: photo,
      enabled: data.enabled,
    };

    try {
      if (partner) {
        await ApiService.patch(`/partner/${partner.partnerId}`, { param: () => d })
      } else {
        await ApiService.post('/partner', { param: () => d })
      }

      navigate('/admin/partner')
      scrollTo();
    } catch (e: any) {
      const error: ApiError = e as ApiError;

      error.errors?.forEach((e: {
        field: string,
        message: string
      }) => {
        setError(e.field as "label" | "description" | "adress" | "link" | "photo" | "enabled", {
          type: "manual",
          message: e.message
        })
      });
    }
  }

  return (
    <>
      <form className="p-3" method="post" onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex">
          <div className="col max-w600px pe-4">
            <div className="form-check form-switch mb-3">
              <input className="form-check-input" type="checkbox" id="form-enabled" {...register('enabled')} />
              <label className="form-check-label" htmlFor="form-enabled">
                Le partenaire est {`${watch('enabled') ? 'visible' : 'masqué'}`}
              </label>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="form-label">Nom <Require/></label>
              <input id="form-label" type="text" className="form-control" placeholder="~" {...register('label', {
                required: 'Le nom est requis',
                validate: {
                  maxLength: (value) =>
                    (value ?? '').length <= 255 || 'Le nom ne doit pas dépasser 255 caractères',
                },
              })} />
              {errors.label && <p className="text-danger">{errors.label.message}</p>}
            </div>
            <div className="mb-3">
              <label className="form-description" htmlFor="form-description">Description</label>
              <input id="form-description" type="text" className="form-control" placeholder="~" {...register('description', {
                validate: {
                  maxLength: (value) =>
                    (value ?? '').length <= 255 || 'La description ne doit pas dépasser 255 caractères',
                },
              })} />
              {errors.description && <p className="text-danger">{errors.description.message}</p>}
            </div>
            <div className="mb-3">
              <label className="form-adress" htmlFor="form-adress">Adresse</label>
              <input id="form-adress" type="text" className="form-control" placeholder="~" {...register('adress', {
                validate: {
                  maxLength: (value) =>
                    (value ?? '').length <= 255 || 'L\'adresse ne doit pas dépasser 255 caractères',
                },
              })} />
              {errors.adress && <p className="text-danger">{errors.adress.message}</p>}
            </div>
            <div className="mb-3">
              <label className="form-link" htmlFor="form-link">Lien</label>
              <input id="form-link" type="text" className="form-control" placeholder="~" {...register('link', {
                validate: {
                  format: (value) =>
                    (value ? pattern_link.test(value) : true) || 'Doit être une adresse internet valide',
                },
              })} />
              {errors.link && <p className="text-danger">{errors.link.message}</p>}
            </div>
          </div>
          <div className="col max-w600px">
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label d-block">Image</label>
              {partner?.photo && (
                <>
                  {!updateImage && !deleteImage && (
                    <>
                      <img src={partner.photo} className="max-h250px img-fluid d-block mb-3" alt={partner.label}/>
                      <button className="btn btn-xs btn-primary me-1" onClick={() => setUpdateImage(true)}>modifier</button>
                      <button className="btn btn-xs btn-danger me-1" onClick={() => setDeleteImage(true)}>supprimer</button>
                    </>
                  )}

                  {updateImage && (
                    <>
                      <label className="form-label italic me-2">si renseignée remplacera l'actuelle</label>
                      <button className="btn btn-xs btn-info me-1" onClick={() => setUpdateImage(false)}>annuler</button>
                    </>
                  )}
                  {deleteImage && (
                    <>
                      <label className="form-label italic me-2">l'image sera supprimée lors de l'enregistrement</label>
                      <button className="btn btn-xs btn-info me-1" onClick={() => setDeleteImage(false)}>annuler</button>
                    </>
                  )}
                </>
              )}
              {(updateImage || !partner?.photo) && (
                <>
                  <input className="form-control form-control-sm" type="file" id="formFile" {...register('photo', {
                    validate: {
                      requiredIfUpdate: (value) =>
                        !updateImage || (value ?? []).length > 0 || 'L\'image est requise',
                    },
                  })}/>
                  {errors.photo && <p className="text-danger">{errors.photo.message}</p>}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3">
          <button className="btn btn-sm btn-success me-1" type="submit">Enregistrer</button>
          <NavLink to={`/admin/partner`} onClick={scrollTo} className="btn btn-sm btn-info">Retour aux partenaires</NavLink>
        </div>
      </form>
    </>
  );
}
