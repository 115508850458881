import {modelHydrator} from "./_modelHydrator";
import {step} from "../step";
import {stepResultType} from "../../types/Api/StepResultType";

export const stepHydrator = {
  hydrate: (step: stepResultType): step => {
    return Object.assign({
      stepId: step.stepId,
      label: step.label,
      order: step.order,
    }, modelHydrator.hydrate(step))
  }
}