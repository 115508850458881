import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {news} from "../models/news";
import {ApiQueryParam, ApiService} from "../service/ApiService";
import {newsHydrator} from "../models/hydrator/newsHydrator";
import {newsResultCollectionType, newsResultType} from "../types/Api/NewsResultType";
import {RootState} from "./store";
import {PaginationType} from "../types/PaginationType";

export const fetchData = createAsyncThunk('get-news', async(_, thunkAPI): Promise<any> => {
  const state: state = (thunkAPI.getState() as RootState).news;
  const delay: number = 60 * 60 * 1000; // 1h

  if ((Date.now() - state.init) < delay) {
    return undefined;
  }

  const apiFilters: ApiQueryParam = new ApiQueryParam();

  apiFilters.addFilter({
    field: 'enabled',
    value: '1'
  });

  apiFilters.addSort({
    by: 'order',
    direction: 'ASC'
  });

  const result: newsResultCollectionType =  await ApiService.get('/news', apiFilters) as newsResultCollectionType;

  return {
    paginate: {
      page: result._page,
      pageCount: result._page_count,
      total: result._total_items
    },
    data: result._embedded.newss.map((news: newsResultType) => {
      return newsHydrator.hydrate(news);
    })
  };
})

interface state {
  init: number;
  loading: boolean;
  paginate?: PaginationType;
  data: news[]
}

const initialState: state = {
  init: 0,
  loading: false,
  paginate: undefined,
  data: []
}

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder): void => {
    builder.addCase(fetchData.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(fetchData.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.paginate = action.payload.paginate;
        state.data = action.payload.data;
        state.init = Date.now();
      }
      state.loading = false;
    })
    builder.addCase(fetchData.rejected, (state) => {
      state.loading = false;
    })
  }
});

export default newsSlice.reducer;