import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Background} from "../components/Background";
import {PasswordForm} from "../components/auth/PasswordForm";

export const Activate = () => {
  const navigate = useNavigate();
  const {userId, token} = useParams() as { userId?: string, token?: string }

  if (userId === undefined || token === undefined) {
    navigate('/admin');
  }

  return (
    <>
      <Background/>
      <div className="__form-login__">
        <div className="__form-login_container__">
          <div className="__form-login_lock__ text-center pb-4">
            <i className="fa-solid fa-circle-user"></i>
          </div>
          <PasswordForm userId={userId ?? ''} token={token ?? ''} />
        </div>
      </div>
    </>

  );
}
