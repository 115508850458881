import React from 'react';
import {album} from "../../../models/album";
import {NavLink} from "react-router-dom";
import {scrollTo} from "../../../service/ToolsService";

export type albumListProps = {
  albums: album[],
  albumSelected?: album
}

export const AlbumList = ({albums, albumSelected}: albumListProps) => {
  return (
    <>
      <div className="d-flex flex-wrap justify-content-center">
        {albums.map((album: album, i: number) => (
          <div key={i} className={`m-3 border border-radius-0_4 ps-3 pe-3 pt-0 pb-0 d-flex flex-column max-w280px w-100 __front_album__ cursor-pointer ${albumSelected && albumSelected.albumId === album.albumId ? '__front_album_selected__' : ''}`}>
              <div className="border border-radius-0_4 h-100 d-flex align-items-center">
                <NavLink to={`/photo/${album.slug}`} className="__front_album_select__ w-100 h-100 p-3" onClick={scrollTo}>
                  <h6 className="m-1 w-100 text-center">{album.label}</h6>
                </NavLink>
              </div>

          </div>
        ))}
      </div>
    </>
  );
}