import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {NavLink} from "react-router-dom";
import {news} from "../../../models/news";
import {newsPostType} from "../../../types/Api/NewsResultType";
import {UploadService} from "../../../service/UploadService";
import {Require} from "../Require";
import {scrollTo} from "../../../service/ToolsService";

type FormDataType = {
  title?: string;
  text?: string;
  photo?: FileList;
  enabled: boolean;
}

type NewsFormProps = {
  news?: news,
  post: (n: newsPostType) => void,
}

export const NewsForm = ({news, post}: NewsFormProps) => {
  const [updateImage, setUpdateImage] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<FormDataType>({
    defaultValues: {
      title: news?.title,
      text: news?.text,
      enabled: news?.enabled ?? true,
    }
  });

  const onSubmit = async (data: FormDataType) => {
    let photo: undefined|string = undefined;

    if (deleteImage) {
      photo = '';
    } else if (data.photo && data.photo.length > 0) {
      photo = await UploadService.handleFileToBase64(data.photo[0]);
    }

    post({
      title: data.title || undefined,
      text: data.text || undefined,
      photo: photo,
      enabled: data.enabled,
    });
  }

  return (
    <>
      <form className="p-3" method="post" onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex">
          <div className="col max-w600px pe-4">
            <div className="form-check form-switch mb-3">
              <input className="form-check-input" type="checkbox" id="form-enabled" {...register('enabled')} />
              <label className="form-check-label" htmlFor="form-enabled">
                {`${watch('enabled') ? 'L\'actualité est visible' : 'L\'actualité est masqué'}`}
              </label>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="form-title">Titre <Require/></label>
              <input id="form-title" type="text" className="form-control" placeholder="~" {...register('title', {
                required: 'Le titre est requis',
                validate: {
                  minLength: (value) =>
                    (value ?? '').length <= 255 || 'Le titre ne doit pas dépasser 255 caractères',
                },
              })} />
              {errors.title && <p className="text-danger">{errors.title.message}</p>}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="form-text">Message</label>
              <textarea id='form-text' className="form-control" rows={10} cols={50} placeholder="~" {...register('text')} />
            </div>
          </div>
          <div className="col max-w600px">
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label d-block">Image</label>
              {news?.photo && (
                <>
                  {!updateImage && !deleteImage && (
                    <>
                      <img src={news.photo} className="max-h250px img-fluid d-block mb-3" alt={news.title}/>
                      <button className="btn btn-xs btn-primary me-1" onClick={() => setUpdateImage(true)}>modifier</button>
                      <button className="btn btn-xs btn-danger me-1" onClick={() => setDeleteImage(true)}>supprimer</button>
                    </>
                  )}

                  {updateImage && (
                    <>
                      <label className="form-label italic me-2">si renseignée remplacera l'actuelle</label>
                      <button className="btn btn-xs btn-info me-1" onClick={() => setUpdateImage(false)}>annuler</button>
                    </>
                  )}
                  {deleteImage && (
                    <>
                      <label className="form-label italic me-2">l'image sera supprimée lors de l'enregistrement</label>
                      <button className="btn btn-xs btn-info me-1" onClick={() => setDeleteImage(false)}>annuler</button>
                    </>
                  )}
                </>
              )}
              {(updateImage || ! news?.photo) && (
                <>
                  <input className="form-control form-control-sm" type="file" id="formFile" {...register('photo', {
                    validate: {
                      requiredIfUpdate: (value) =>
                        !updateImage || (value ?? []).length > 0 || 'L\'image est requise',
                    },
                  })}/>
                  {errors.photo && <p className="text-danger">{errors.photo.message}</p>}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3">
          <button className="btn btn-sm btn-success me-1" type="submit">Enregistrer</button>
          <NavLink to={`/admin/news`} onClick={scrollTo} className="btn btn-sm btn-info">Retour aux actualités</NavLink>
        </div>
      </form>
    </>
  );
}
