import React, {useCallback, useEffect, useState} from 'react';
import {race} from "../../../models/race";
import DateService from "../../../service/DateService";
import {ApiQueryParam, ApiService} from "../../../service/ApiService";
import {participant} from "../../../models/participant";
import {participantResultCollectionType, participantResultType} from "../../../types/Api/ParticipantResultType";
import {participantHydrator} from "../../../models/hydrator/participantHydrator";
import ContentLoader from "react-content-loader";

export type CalendrierInscriptionModalProps = {
  race: race;
  close: () => void;
}

export const CalendrierInscriptionModal = ({race, close}: CalendrierInscriptionModalProps) => {
  const [loader, setLoader] = useState(0);
  const [loading, setLoading] = useState(false);
  const [participants, setParticipants] = useState([] as participant[]);
  const [participation, setParticipation] = useState('');
  const [saving, setSaving] = useState(false);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!saving) {
      setSaving(true);

      await ApiService.post('/participant', {
        param(): {} {
          return {
            "name": participation,
            "raceId": race.raceId
          }
        }
      });

      await loadParticipants();

      setParticipation('');
      setSaving(false);
    }
  }

  const loadParticipants = useCallback(async () => {
    const apiFilters: ApiQueryParam = new ApiQueryParam();

    apiFilters.setCount(100);

    apiFilters.addFilter({
      field: 'raceId',
      op: 'eq',
      value: race.raceId
    });

    apiFilters.addSort({
      by: 'created_at',
      direction: 'ASC'
    })

    setLoading(true);
    const result: participantResultCollectionType = await ApiService.get(`/participant`, apiFilters) as participantResultCollectionType;

    setParticipants(result._embedded.participants.map((participant: participantResultType) => {
      return participantHydrator.hydrate(participant);
    }));
    setLoading(false);
  }, [setParticipants, race]);

  useEffect(() => {
    setLoader(3)
    loadParticipants().then(() => {
      setLoader(1)
    });
  }, [loadParticipants]);

  return (
    <>
      <div className="__modal_container__ d-flex justify-content-center align-items-center">
        <div className="__modal_background__" onClick={() => close()}/>
        <div className="__modal_box__ __front_box__ max-w70 max-h80">
          <i className="float-end cursor-pointer fa-solid fa-circle-xmark text-info" onClick={() => close()}/>
          <div>
            <h2 className="__front_info_title__ m-0">Inscription à la {race.ranking ? 'course' : 'randonnée'}</h2>
            <hr className="w-50 ms-auto me-auto mt-3 mb-4"/>
          </div>

          <div>
            <p className="text-center">{race.label}</p>
            <ul>
              <li>
                <span className="text-decoration-underline">date</span> :&nbsp;
                {DateService.toLocaleString(race.rideDate, {weekday: 'long', day: '2-digit', month: 'short', year: 'numeric'})}
              </li>
              <li>
                <span className="text-decoration-underline me-1">inscription</span> :
                {race.startRegistration && (
                  <span>
                    {race.endRegistration ? ' du ' : ' à partir du '}
                    {DateService.toLocaleString(race.startRegistration, {day: '2-digit', month: 'short', year: 'numeric'})}
                  </span>
                )}
                {race.endRegistration && (
                  <span>
                    {race.startRegistration ? 'au ' : ' jusqu\'au '}
                    {DateService.toLocaleString(race.endRegistration, {day: '2-digit', month: 'short', year: 'numeric'})}
                  </span>
                )}
              </li>
              {participants.length > 0 && (
                <li>
                  <span className="text-decoration-underline">participants</span> :
                  <ul>
                    {participants.map((participant: participant, i: number) => (
                      <li key={i}>{participant.name}</li>
                    ))}
                  </ul>
                </li>
              )}

              {loading && (
                <>
                  {[...new Array(loader)].map((i: number, index: number) => (
                    <ContentLoader
                      key={index}
                      speed={3}
                      width="100"
                      height="100%"
                      viewBox="0 0 50 6"
                      backgroundColor="#e1e1ff"
                      foregroundColor="#8b8bf9"
                      className="d-block mt-1 mb-1"
                    >
                      <rect x="0" y="0" rx="3" ry="3" width="50" height="6"/>
                    </ContentLoader>
                  ))}
                </>
              )}
            </ul>
            <form onSubmit={(e: React.FormEvent) => submit(e)}>
              <div className="d-flex mt-2  align-items-center flex-wrap max-w90 w400px">
                <div className="min-w100px me-2">
                  <input placeholder="ajouter un participant"
                         type="text"
                         name="participant"
                         className={`form-control max-w250px min-w100px ${saving ? 'disabled' : ''}`}
                         onChange={(e) => setParticipation(e.target.value)}
                         disabled={saving}
                         value={participation}
                  />
                </div>
                <div className="">
                  <button type="submit" className={`btn btn-sm btn-success ${saving ? 'disabled' : ''}`}>
                    {saving && (
                      <span className="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
                    )}
                    confirmer
                  </button>
                </div>
              </div>
            </form>
          </div>

          <p className="m-0 mt-5 text-end">
            <button className="btn btn-sm btn-info" onClick={() => close()}>fermer</button>
          </p>
        </div>
      </div>
    </>
  );
}
