import React from 'react';
import {SortableContainer, SortableElement, SortEnd} from "react-sortable-hoc";
import {news} from "../../../models/news";
import {arrayMoveMutable} from 'array-move';
import {NavLink} from "react-router-dom";
import {scrollTo} from "../../../service/ToolsService";

type SortableItemProps = {
  news: news
}

const SortableItem = SortableElement<SortableItemProps>(({news}: SortableItemProps) => (
  <div className="bg-white border p-2 m-1 cursor-pointer d-flex align-items-center">
    <p className="m-0 p-0">
      <i className="fa-solid fa-sort me-3" />
    </p>
    <p className="m-0 p-0">
      {news.title}
    </p>
    <p className="m-0 p-0 w90px text-end ms-auto">
      {news.text && (<i className="ms-2 fa-regular fa-file-lines"/>)}
      {news.photo && (<i className="ms-2 fa-regular fa-image"/>)}
      {news.enabled && (<i className="ms-2 fa-solid fa-eye"/>)}
    </p>
  </div>
));

type SortableListProps = {
  newsList: news[]
}

const SortableList = SortableContainer<SortableListProps>(({newsList}: SortableListProps) => {
  return (
    <div className="max-w600px">
      {newsList.map((news, index) => (
        <SortableItem key={index} index={index} news={news}/>
      ))}
    </div>
  );
});

type NewsSortableProps = {
  news: news[],
  save: (n: news[]) => void,
}

export const NewsSortable = ({news, save}: NewsSortableProps) => {
  const onSortEnd = ({oldIndex, newIndex}: SortEnd) => {
    arrayMoveMutable(news, oldIndex, newIndex);
  };

  return (
    <>
      <div className="d-flex">
        <SortableList newsList={news} onSortEnd={onSortEnd}/>
      </div>
      <div className="mt-2">
        <button type="button" className="btn btn-sm btn-success me-1" onClick={() => save(news)}>Enregistrer</button>
        <NavLink to={`/admin/news`} onClick={scrollTo} className="btn btn-sm btn-info">Retour aux actualités</NavLink>
      </div>
    </>
  );
}
