import {modelHydrator} from "./_modelHydrator";
import {memberResultType} from "../../types/Api/MemberResultType";
import {member} from "../member";

export const memberHydrator = {
  hydrate: (member: memberResultType): member => {
    return Object.assign({
      memberId: member.memberId,
      name: member.name,
      firstname: member.firstname,
      email: member.email,
      avatar: member.avatar,
    }, modelHydrator.hydrate(member))
  }
}