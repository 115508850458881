import React from 'react';

export type ForbiddenProps = {
  module?: string
}

export const Forbidden = ({module}: ForbiddenProps) => {
  return (
    <div className="text-center max-w800px">
      <h2 className="h2 p-3 m-5">
        <i className="fa-solid fa-triangle-exclamation text-danger me-3"/>
        Accès Interdit
      </h2>
      <div>
        Vous n'avez pas les autorisations nécessaires pour accéder à cette ressource.<br />
        Si vous pensez qu'il s'agit d'une erreur, veuillez contacter l'administrateur du site.
      </div>
    </div>
  );
}
