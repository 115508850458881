import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AuthService} from "../service/AuthService";
import {user} from "../models/user";
import {ApiService} from "../service/ApiService";
import {userResultType} from "../types/Api/UserResultType";
import {userHydrator} from "../models/hydrator/userHydrator";

export const me = createAsyncThunk('me', async (_): Promise<any> => {
  const user: userResultType = await ApiService.get('/me') as userResultType;

  return userHydrator.hydrate(user);
})

export const login = createAsyncThunk('auth/login', async (credential: {username: string, password: string}): Promise<any> => {
  return await AuthService.loginByUsernamePassword(credential.username, credential.password);
});

export const loginByRefresh = createAsyncThunk('auth/refresh', async (_): Promise<any> => {
  return await AuthService.loginByRefreshToken();
});

interface state {
  message?: string,
  loading: boolean,
  loadingMe: boolean,
  refresh: boolean,
  accessToken?: string,
  me?: user,
}

const initialState: state = {
  message: undefined,
  loading: false,
  loadingMe: false,
  refresh: false,
  accessToken: undefined,
  me: undefined,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    clearToken: (state) => {
      state.accessToken = undefined;
    },
  },
  extraReducers: (builder): void => {
    builder
      // Auth
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.accessToken = action.payload;

        state.loading = false;
        state.message = undefined;
      })
      .addCase(login.rejected, (state, action) => {
        state.message = action.error.message;

        state.loading = false;
      })

      // Refresh
      .addCase(loginByRefresh.pending, (state) => {
        state.refresh = true;
      })
      .addCase(loginByRefresh.fulfilled, (state, action) => {
        state.accessToken = action.payload;

        state.refresh = false;
      })
      .addCase(loginByRefresh.rejected, (state) => {
        state.refresh = false;
      })

      // Me
      .addCase(me.pending, (state) => {
        state.loadingMe = true;
      })
      .addCase(me.fulfilled, (state, action) => {
        state.me = action.payload;

        state.loadingMe = false;
      })
      .addCase(me.rejected, (state) => {
        state.loadingMe = false;
      })
  }
});

export const { setToken, clearToken } = authSlice.actions;
export default authSlice.reducer;