import React from 'react';
import {office} from "../../../models/office";
import {OfficeListBox} from "./OfficeListBox";

type OfficeListProps = {
  offices: office[],
}

export const OfficeList = ({offices}: OfficeListProps) => {
  return (
    <div className="d-flex align-items-stretch flex-wrap">
      {offices.map((office: office, index: number) => (
        <div key={index} className="w100 max-w350px p-2">
          <OfficeListBox office={office}/>
        </div>
      ))}
    </div>
  )
}
