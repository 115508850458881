import {createdAtType} from "../../types/Api/ApiResultType";
import {model} from "../_model";

export const modelHydrator = {
  hydrate: (model: createdAtType): model => {
    return {
      createdAt: model.createdAt,
      updatedAt: model.updatedAt ?? undefined
    }
  }
}