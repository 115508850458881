import React from 'react';
import {Outlet} from "react-router-dom";
import {AuthService} from "../../service/AuthService";
import {Forbidden} from "../../components/Forbidden";

export const News = () => {
  return (
    <>
      { !AuthService.hasRole('ROLE_ACTUALITE') && (
        <Forbidden module="user"/>
      )}
      { AuthService.hasRole('ROLE_ACTUALITE') && (
        <Outlet/>
      )}
    </>
  );
}
