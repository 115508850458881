import {modelHydrator} from "./_modelHydrator";
import {partnerResultType} from "../../types/Api/PartnerResultType";
import {partner} from "../partner";

export const partnerHydrator = {
  hydrate: (partner: partnerResultType): partner => {
    return Object.assign({
      partnerId: partner.partnerId,
      label: partner.label,
      description: partner.description,
      adress: partner.adress,
      link: partner.link,
      photo: partner.photo,
      enabled: partner.enabled === 1
    }, modelHydrator.hydrate(partner))
  }
}