import React from 'react';
import {NavLink} from "react-router-dom";
import {scrollTo} from "../../service/ToolsService";

export const Header = () => {
  return (
    <>
      <div className="__admin_header__">
        <div className="d-flex flex-nowrap align-items-center ps-3 pe-3">
          <div className="col max-w300px">
            <h2 className="__front_h2__"><NavLink to="/admin" onClick={scrollTo}>C’Team Chartres</NavLink></h2>
          </div>
          <div className="col align-items-center text-end __admin_header_action__">
            <NavLink to="/" onClick={scrollTo} className="me-3">retourner sur le site</NavLink>
            <i className="fa-solid fa-circle-user cursor-pointer"/>
          </div>
        </div>
      </div>
    </>
  );
}
