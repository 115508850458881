import React, {useCallback, useEffect, useState} from 'react';
import {TableLineLoader} from "../../front/loader/TableLineLoader";
import DateService from "../../../service/DateService";
import {NavLink} from "react-router-dom";
import {PaginationType} from "../../../types/PaginationType";
import {ApiQueryParam, ApiService} from "../../../service/ApiService";
import {userResultCollectionType, userResultType} from "../../../types/Api/UserResultType";
import {userHydrator} from "../../../models/hydrator/userHydrator";
import {DeleteConfirme} from "../DeleteConfirme";
import {Pagination} from "../Pagination";
import {user} from "../../../models/user";
import {scrollTo} from "../../../service/ToolsService";

export const UserList = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([] as user[]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [del, setDel] = useState(undefined as undefined | user);
  const [pagination, setPagination] = useState({
    page: 0,
    pageCount: 0,
    total: 0,
    count: count,
  } as PaginationType);

  const deleteUser = async (user: user) => {
    setLoading(true);
    await ApiService.delete(`/user/${user.userId}`);

    findUser();
  };

  const findUser = useCallback((): void => {
    const apiFilters: ApiQueryParam = new ApiQueryParam();

    apiFilters.addSort({
      by: 'username',
      direction: 'ASC'
    });

    apiFilters.setPage(page);
    apiFilters.setCount(count);

    setLoading(true);
    const result: Promise<userResultCollectionType> = ApiService.get('/user', apiFilters) as Promise<userResultCollectionType>;
    result
      .then((r: userResultCollectionType) => {
        setPagination({
          page: r._page,
          pageCount: r._page_count,
          total: r._total_items,
          count: count,
        });

        setUser(r._embedded.users.map((user: userResultType) => {
          return userHydrator.hydrate(user);
        }));
      })
      .catch(() => {
        alert("Une erreur c'est produite.")

        setPagination({
          page: 0,
          pageCount: 0,
          total: 0,
          count: count,
        });
        setUser([]);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [page, count]);

  const status = (user: user) => {
    if (! user.verified) {
      return 'en cours'
    }

    if (! user.enabled) {
      return 'incatif';
    }

    return 'actif';
  }

  useEffect(() => {
    findUser();
  }, [findUser]);

  return (
    <>
      <table className="table table-responsive table-hover border">
        <thead>
        <tr className="table-primary">
          <th>Login</th>
          <th>Email</th>
          <th>Roles</th>
          <th>Status</th>
          <th className="text-center w150px">Modification</th>
          <th className="w250px"></th>
        </tr>
        </thead>
        <tbody>
        {loading && (
          <tr>
            <td colSpan={5} className="pt-4 pb-4 ps-5 pe-5">
              <TableLineLoader/>
            </td>
          </tr>
        )}

        {!loading && user.map((n: user, i: number) => (
          <tr key={i} className={`align-middle` + (n.verified ? '' : ' table-info')}>
            <td>{n.username}</td>
            <td>{n.email}</td>
            <td>{n.roles.map((r: string, i: number) => (<p className="m-0 p-0" key={i}>{r}</p>))}</td>
            <td>
              {status(n)}
            </td>
            <td className="text-center">
              {DateService
                .toLocaleString(n.updatedAt ?? n.createdAt, {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false})
              }
            </td>
            <td className="text-end">
              <NavLink to={`/admin/user/edit/${n.userId}`} onClick={scrollTo} className="btn btn-xs btn-primary me-1">
                <i className="fa-solid fa-pencil me-1"/> modifier
              </NavLink>
              <button className="btn btn-xs btn-danger" onClick={() => setDel(n)}><i className="fa-solid fa-xmark me-1"/>supprimer</button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      {del && (
        <DeleteConfirme confirme={(c: boolean) => {
          if (c) {
            deleteUser(del);
          }

          setDel(undefined);
        }}/>
      )}
      <Pagination pagination={pagination}
                  paginate={(p: number) => {
                    if (!loading) {
                      setPage(p);
                    }
                  }}
                  count={(c: number) => {
                    if (!loading) {
                      setPage(1);
                      setCount(c);
                    }
                  }}
      />
      <div className="mt-3 text-end">
        <NavLink to={`/admin/user/add`} onClick={scrollTo} className="btn btn-sm btn-primary me-1">
          <i className="fa-solid fa-plus me-1"/> Ajouter un utilisateur
        </NavLink>
      </div>
    </>
  );
}
